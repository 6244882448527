import React from "react";
import { PropertyView, UserView } from "../../Utilities/Types";
// @ts-ignore
import Naira from "react-naira";

export default function UserListings({ users }: { users?: UserView }) {
	return (
		<div className="listing-tab-content mt-4">
			<div className="row">
				<div className="col-lg-4">
					<div className="listing-title">Drafts</div>
					{users?.properties.map((user, i) => {
						return user.isDraft ? (
							<div className="single-listing">
								<div className="flex-item">
									<div className="listing-img">
										<img
											src={
												user?.mediaFiles && user?.mediaFiles.length > 0
													? user.mediaFiles[0].url
													: ""
											}
											alt=""
										/>
									</div>
									<div className="listing-info">
										<h6>{user?.name}</h6>
										<div className="flex-item">
											<span className="mr-1">Seller</span>
											<small>{users.firstName}</small>
										</div>
										<div className="flex mb-1">
											<i className="fal fa-tags" />
											<span>
												<Naira>{user?.price}</Naira>
											</span>
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						);
					})}
				</div>
				<div className="col-lg-4">
					<div className="listing-title">For Sale</div>
					{users?.properties.map((user, i) => {
						return user.isForSale ? (
							<div className="single-listing">
								<div className="flex-item">
									<div className="listing-img">
										<img
											src={
												user?.mediaFiles && user?.mediaFiles.length > 0
													? user.mediaFiles[0].url
													: ""
											}
											alt=""
										/>
									</div>
									<div className="listing-info">
										<h6>{user?.name}</h6>
										<div className="flex">
											<span className="mr-1">Location</span>
											<small>{`${user.area},${user.state}`}</small>
										</div>
										<div className="flex mb-1">
											<i className="fal fa-tags" />
											<span>
												<Naira>{user?.price}</Naira>
											</span>
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						);
					})}
				</div>
				<div className="col-lg-4">
					<div className="listing-title">Active Listings</div>
					{users?.properties.map((user, i) => {
						return user.isActive ? (
							<div className="single-listing">
								<div className="flex-item">
									<div className="listing-img">
										<img
											src={
												user?.mediaFiles && user?.mediaFiles.length > 0
													? user.mediaFiles[0].url
													: ""
											}
											alt=""
										/>
									</div>
									<div className="listing-info">
										<h6>{user?.name}</h6>
										<div className="flex">
											<span className="mr-1">Seller</span>
											<small>{users.fullName}</small>
										</div>
										<div className="flex mb-1">
											<i className="fal fa-tags" />
											<span>
												<Naira>{user?.price}</Naira>
											</span>
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						);
					})}
				</div>
			</div>
		</div>
	);
}
