import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { CleanView } from "../../Utilities/Types";

export default function CleaningList({ cleanings,}: { cleanings?: CleanView[]; }) {
  console.log({cleanings})
  const [miniTab, setMiniTab] = useState<any>("pending");
  const setTab = (tabname: string) => {
		setMiniTab(tabname);
	};
  
  return (
    <>
      <div className="inner-wrapper">
        <div className="flex all-tabs" >
          <div className={`mini-tabs ${miniTab == "pending" ? "active" : ""}`} onClick={() => setTab('pending')}>Pending</div>
          <div className={`mini-tabs ${miniTab == "closed" ? "active" : ""}`} onClick={() => setTab('closed')}>Closed</div>
        </div>
        <div className="flex-item mb-4">
          <div className="admin-search-bar-2">
            <i className="fas fa-search" />
            <input
              type="text"
              className="property-search"
              placeholder="Search"
            />
          </div>
          <button className="primary-btn">Add New Job</button>
        </div>
        <div className="table-box">
          <table className="table table-hover">
            <thead className="ag-thead">
              <tr>
                <th scope="col">Building Type</th>
                <th scope="col">User</th>
                <th scope="col">Purpose</th>
                <th scope="col">State</th>
                <th scope="col">Floors</th>
                <th scope="col">Location</th>
                <th scope="col">Preferred Date</th>
              </tr>
            </thead>
            <tbody>
              {cleanings &&
                cleanings.map((clean, index) => {
                  return (
                    <tr>
                      <Link to={{ pathname:`/cleanings/${clean.id}`,
                      state: { 
                        clean: clean
                      }
					  }}><th scope="row">{clean.propertyType}</th></Link>
                      <td>{clean.user?.fullName}</td>
                      <td>{clean.buildingType}</td>
                      <td>{clean.buildingState}</td>
                      <td>{clean.numberOfFloors}</td>
                      <td>{""}</td>
                      <td><Moment format="DD/MM/YY">
                      {clean.dateNeeded}
            </Moment></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="pm-space" />
        <div className="pagination">
          <div className="page-arrows">
            <i className="fal fa-angle-left" />
          </div>
          <div className="page-number">1</div>
          <div className="page-number nb">of 238</div>
          <div className="page-arrows">
            <i className="fal fa-angle-right" />
          </div>
        </div>
      </div>
    </>
  );
}
