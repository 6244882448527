import React from "react";
import { MetroSpinner } from "react-spinners-kit";

export default function Spinner(props: any) {
	const { color, size } = props;
	return (
		<div>
			<MetroSpinner
				color={!color ? "#fff" : color == "primary" ? "0042ff" : color}
				size={!size ? "20" : size}
				loading={true}
			/>
		</div>
	);
}
