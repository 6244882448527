import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import { AllListings } from "../../../Classes/AllListings";
import Fetch from "../../../Utilities/Fetch";
import Spinner from "../../../Utilities/Spinner";
import { MediaInput, PropertyInput } from "../../../Utilities/Types";
// @ts-ignore
import NaijaStates from "naija-state-local-government";
import { PropertyModel } from "../../../Types/api";

export default function AddProperty({
	close,
	list,
	requestId
}: {
	close: any;
	list?: any;
	requestId?:number
}) {
	const initialValues: PropertyModel = {
		name: "",
		title: "",
		address: "",
		description: "",
		sellMyself: false,
		price: 0,
		numberOfBedrooms: 0,
		numberOfBathrooms: 0,
		isDraft: false,
		isActive: true,
		isForRent: false,
		isForSale: true,
		propertyTypeId: 0,
		mediaFiles: [],
		state: "",
		lga: "",
		area: "",
		isRequest: false,
		comment: "",
		budget: "",
		propertyRequestMatchId:requestId,
	};

	const [loading, setLoading] = useState(false);
	const [mediaFiles, setMediaFiles] = useState<MediaInput[]>([]);
	const [previews, setPreviews] = useState<string[]>([]);
	const [videoPreviews, setVideoPreviews] = useState<string[]>([]);
	const { addToast } = useToasts();
	const [propertyTypes, setPropertyTypes] = useState<[]>();
	const [selectedState, setSelectedState] = useState("");
	const createProperty = async (details: PropertyModel) => {
		setLoading(true);
		let data = await AllListings.addProperty(details);
		console.log(data);
		if (data.status) {
			addToast(data.message, { appearance: "success", autoDismiss: true });
			close();
			await list();
			setLoading(false);
			return;
		}
		addToast(data.message, { appearance: "error", autoDismiss: true });
		setLoading(false);
		close();
		return;
	};


	const grabUploadedFile = (uploadedFiles: any) => {
		console.log(uploadedFiles);
		extractPreviewFromFile(uploadedFiles);
		console.log({ previews });

		uploadedFiles.forEach((file: any) => {
			const reader = new FileReader();

			reader.onabort = () => {
				console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
			};
			reader.onerror = () => {
				console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
			};
			reader.onload = async () => {
				// Do whatever you want with the file contents
				const binaryStr = (reader.result as string).split(",")[1];
				console.log(reader.result);
				//console.log(binaryStr);
				console.log(binaryStr);
				await composeMedia(binaryStr, file);
			};
			console.log(file);
			reader.readAsDataURL(file);
		});
	};

	const getTypes = async () => {
		try {
			const data = await AllListings.ListTypes();
			if (data.status) {
				setPropertyTypes(data.data);
				return;
			}
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const grabUploadedVideoFile = (uploadedFiles: any) => {
		console.log(uploadedFiles);
		extractPreviewFromFile(uploadedFiles, true);
		console.log({ previews });

		uploadedFiles.forEach((file: any) => {
			const reader = new FileReader();

			reader.onabort = () => {
				console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
			};
			reader.onerror = () => {
				console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
			};
			reader.onload = async () => {
				// Do whatever you want with the file contents
				const binaryStr = (reader.result as string).split(",")[1];
				console.log(reader.result);
				//console.log(binaryStr);
				console.log(binaryStr);
				await composeMedia(binaryStr, file);
			};
			console.log(file);
			reader.readAsDataURL(file);
		});
	};

	console.log();

	const extractPreviewFromFile = async (
		uploadedFiles: any[],
		isVideo = false
	) => {
		var newState: string[] = [];
		uploadedFiles.map((element) => {
			console.log(element);
			newState.push(URL.createObjectURL(element));
		});
		if (isVideo) {
			setVideoPreviews([...videoPreviews, ...newState]);
			return;
		}
		setPreviews([...previews, ...newState]);
	};

	const composeMedia = async (bytes: any, file: any) => {
		var files: any[] = [];
		console.log(files);
		var newMedia: MediaInput = {
			name: file.name,
			extention: getFileExtension(file.name),
			base64String: bytes,
			propertyId: 0,
			isImage:
				getFileExtension(file.name) == "jpeg" ||
				getFileExtension(file.name) == "jpg"
					? true
					: false,
			isVideo: getFileExtension(file.name) == "mp4" ? true : false,
			isDocument: getFileExtension(file.name) == "pdf" ? true : false,
		};

		files.push(newMedia);
		setMediaFiles([...mediaFiles, newMedia]);
	};

	const getFileExtension = (fileName: any) => {
		return fileName.split(".")[1];
	};
	useEffect(() => {
		const fetchInitialTypes = () => {
			getTypes();
		};
		fetchInitialTypes();
	}, []);
	return (
		<>
			<div className="flex-item">
				<div className="item-page">Add a New Property</div>
				<i className="fal fa-times cancel" onClick={close} />
			</div>
			<Formik
				initialValues={initialValues}
				onSubmit={async (
					values: PropertyModel,
					{ setSubmitting }: FormikHelpers<PropertyModel>
				) => {
					values.state = selectedState;
					values.mediaFiles = mediaFiles;
					console.log(values);
					await createProperty(values);
				}}
			>
				<Form>
					<div className="row px-2rem">
						<div className="col-lg-6">
							<div className="property-info-box">
								<div className="input-box">
									<div className="input-label">Property Name</div>
									<Field id="name" name="name" className="formfield" required />
								</div>
								<div className="input-box">
									<div className="input-label">Legal Name</div>
									<Field
										id="title"
										name="title"
										className="formfield"
										required
									/>
								</div>
								<div className="input-box">
									<div className="input-label">No. of Bedrooms</div>
									<Field
										id="name"
										name="numberOfBedrooms"
										className="formfield"
										required
									/>
								</div>
								<div className="input-box">
									<div className="input-label">No. of Toilets</div>
									<Field
										id="name"
										name="numberOfBathrooms"
										className="formfield"
										required
									/>
								</div>
								<div className="input-box">
									<div className="input-label">Price</div>
									<Field
										id="name"
										name="price"
										className="formfield"
										required
									/>
								</div>
								<div className="input-box">
									<div className="input-label">State</div>
									<div className="select-box">
										<select
											name="state"
											className="formfield"
											required
											onChange={(e) => setSelectedState(e.target.value)}
										>
											{NaijaStates.states().map((state: any, index: any) => {
												return (
													<option value={state} key={index}>
														{state}
													</option>
												);
											})}
										</select>
										<div className="arrows"></div>
									</div>
								</div>
								<div className="input-box">
									<div className="input-label">Locality</div>
									<div className="select-box">
										<Field
											as="select"
											name="lga"
											className="formfield"
											required
										>
											{selectedState &&
												NaijaStates.lgas(selectedState).lgas.map(
													(lga: any, i: any) => {
														return (
															<option value={lga} key={i}>
																{lga}
															</option>
														);
													}
												)}
										</Field>
										<div className="arrows"></div>
									</div>
								</div>
								<div className="input-box">
									<div className="input-label">Area</div>
									<div className="select-box">
										<Field
											as="select"
											name="area"
											className="formfield"
											required
										>
											{selectedState &&
												NaijaStates.lgas(
													selectedState
												).senatorial_districts.map((lga: any, i: any) => {
													return (
														<option value={lga} key={i}>
															{lga}
														</option>
													);
												})}
										</Field>
										<div className="arrows"></div>
									</div>
								</div>
								<div className="input-box d-none">
									<div className="input-label">Commision</div>
									<input
										type="text"
										className="formfield"
										placeholder="Lawyer"
									/>
								</div>
								<div className="input-box">
									<div className="input-label">Address</div>
									<div className="select-box">
										<Field
											id="area"
											name="address"
											className="formfield"
											required
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="property-info-box">
								<div className="input-box d-none">
									<div className="input-label">Map</div>
									<div className="select-box">
										<select className="formfield">
											<option>Choose an option</option>
										</select>
										<div className="arrows" />
									</div>
								</div>
								<div className="input-box">
									<div className="input-label">Property Type</div>
									<Field
										as="select"
										name="propertyTypeId"
										className="formfield"
										required
									>
										<option></option>
										{propertyTypes?.map((type: any, index) => {
											return <option value={type.id}>{type.name}</option>;
										})}
									</Field>
									<div className="arrows"></div>
								</div>

								<div className="input-box">
									<div className="input-label">Details</div>
									<Field
										as="textarea"
										id="description"
										name="description"
										className="input-txt"
										required
										style={{ height: "150px" }}
									/>
									<div className="notes">
										Use | to separate individual detail
									</div>
								</div>
								<div className="single-form">
									<label className="admin-form">Add Images</label>
									<div className="flex img-container">
										<Dropzone
											accept="image/jpeg, image/png"
											maxFiles={6}
											onDrop={(acceptedFiles) =>
												grabUploadedFile(acceptedFiles)
											}
										>
											{({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()} className="admin-img-field">
														<input {...getInputProps()} />
													</div>
												</section>
											)}
										</Dropzone>
										{previews.map((preview, index) => {
											return (
												<img src={preview} className="admin-img-field" alt="" />
											);
										})}
									</div>
								</div>
								<div className="single-form">
									<label className="admin-form">Add Interactive 3D tour</label>
									<div className="flex img-container">
										{videoPreviews.map((preview, index) => {
											return (
												<video
													src={preview}
													className="admin-img-field"
													controls
												/>
											);
										})}
										<Dropzone
											accept="video/mp4"
											maxFiles={6}
											onDrop={(acceptedFiles) =>
												grabUploadedVideoFile(acceptedFiles)
											}
										>
											{({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()} className="admin-img-field">
														<input {...getInputProps()} />
													</div>
												</section>
											)}
										</Dropzone>
									</div>
								</div>
								<button className="secondary-btn w-100" type="submit">
									{loading ? <Spinner /> : "Add Property"}
								</button>
							</div>
						</div>
					</div>
				</Form>
			</Formik>
		</>
	);
}
function addToast(
	message: string,
	arg1: { appearance: string; autoDismiss: boolean }
) {
	throw new Error("Function not implemented.");
}
