import React, { useState } from "react";
import MiniModal from "../../Utilities/Modal/MiniModal";
import Spinner from "../../Utilities/Spinner";
import { UserView } from "../../Utilities/Types";

export default function AdminProfile({
	user,
	deleteUser,
}: {
	user?: UserView;
	deleteUser: any;
}) {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	return (
		<>
			<MiniModal
				open={deleteModalOpen}
				onClose={() => {
					setDeleteModalOpen(false);
				}}
				height={"150px"}
				width={"30%"}
			>
				<div className="flex-column justify-content-center">
					<h5 className="text-center">
						Are you sure you want to delete <span>{user?.fullName} </span>
					</h5>
					<div className="row  flex-center justify-space-between mt-3">
						<button
							className="user-red-btn-a "
							style={{ backgroundColor: "#000", marginRight: "1rem" }}
							onClick={async () => await setDeleteModalOpen(false)}
						>
							No
						</button>

						<button
							className="user-red-btn-a "
							onClick={async () => await deleteUser(user?.email)}
						>
							{loading ? <Spinner /> : "Yes"}
						</button>
					</div>
				</div>
			</MiniModal>

			<div className="profile-tab-content">
				<div className="row">
					<div className="col-lg-4">
						<div className="per-details">
							<label className="tab-label">First Name</label>
							<span className="tab-text">{user?.firstName}</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Surname</label>
							<span className="tab-text">{user?.lastName}</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Email</label>
							<span className="tab-text">{user?.email}</span>
						</div>
						<button
							className="btn-outline"
							onClick={async () => await setDeleteModalOpen(true)}
						>
							Remove Admin
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
