import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Cleaning } from "../../Classes/Cleaning";
import { CleanView, PagedApiResponseData } from "../../Utilities/Types";
import CleaningList from "./CleaningList";

export default function CleaningBody() {
  const [tab, setTab] = useState("cleanings");
  const { addToast } = useToasts();
  const [requests, setRequests] = useState<CleanView[]>();
  const [requestResponse, setRequestResponse] =
    useState<PagedApiResponseData>();
  const listRequests = async () => {
    try {
      const data = await Cleaning.listRequests();
      console.log({ data: data });

      if (!data.status) {
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      addToast(data.message, { appearance: "success", autoDismiss: true });
      setRequests(data.data.value as unknown as CleanView[]);
      setRequestResponse(data.data);
      return;
    } catch (error) {
      console.error(error);
      addToast("An error occurred", { appearance: "error", autoDismiss: true });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await listRequests();
    };
    fetchData();
  }, []);
  return (
    <div className="admin-inner">
      <div className="flex">
        <div
          className={tab == "cleanings" ? "pm-tab active" : "pm-tab"}
          onClick={() => {
            setTab("cleanings");
          }}
        >
          <div className="flex">
            <i className="far fa-users" />
            <span>Cleanings</span>
          </div>
          <div className="pm-tab-count">{requestResponse?.size}</div>
        </div>
        <div
          className={tab == "sessions" ? "pm-tab active" : "pm-tab"}
          onClick={() => {
            setTab("sessions");
          }}
        >
          <div className="flex">
            <i className="fas fa-bell" />
            <span>Sessions</span>
          </div>
          <div className="pm-tab-count">0</div>
        </div>
      </div>
      {tab == "cleanings" ? <CleaningList cleanings={requests} /> : null}
    </div>
  );
}
