import React, { useEffect, useState, } from "react";
import { TransactionView } from "../../Utilities/Types";
import { useToasts } from "react-toast-notifications";
import { Transaction } from "../../Classes/Transactions";
import Fetch from "../../Utilities/Fetch";
import Spinner from "../../Utilities/Spinner";
//@ts-ignore
import Naira from 'react-naira';

export default function Transactions() {
  const { addToast } = useToasts();
  const [transactions, setTransactions] = useState<TransactionView[]>();
  const [loading, setLoading] = useState<boolean>(false)


  const listTransactions = async () => {
    try {
      setLoading(true)
      const data = await Transaction.listTransactions();
      console.log({ data: data });

      if (!data.status) {
        addToast(data.message, { appearance: "error", autoDismiss: true });
        setLoading(false)
        return;
      }
      addToast(data.message, { appearance: "success", autoDismiss: true });
      setTransactions(data.data.value as unknown as TransactionView[]);
      setLoading(false)
      console.log(data.data.value);

      return;
    } catch (error) {
      console.error(error);
      addToast("An error occurred", { appearance: "error" });
      setLoading(false)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await listTransactions();
    };
    fetchData();
  }, []);

  return (
    <div className="admin-inner">
      <div className="inner-wrapper">
        <div className="flex-item mb-4">
          <div className="admin-search-bar-2">
            <i className="fas fa-search" />
            <input
              type="text"
              className="property-search"
              placeholder="Search"
            />
          </div>
          <div className="flex">
            <div className="admin-action">Export</div>
            <div className="admin-action">7 Feb-8 Feb 2020</div>
            <div className="admin-action">
              <select>
                <option value="">Filter</option>
              </select>
            </div>
          </div>
        </div>
        <div className="table-box">
          <table className="table table-hover">
            <thead className="ag-thead">
              <tr>
                <th scope="col">
                  <input type="checkbox" className="t-check" />
                  User
                </th>
                <th scope="col">Amount</th>
                <th scope="col">Type</th>
                <th scope="col">Service</th>
                <th scope="col">Recipient</th>
                <th scope="col">Provider</th>
                <th scope="col">Channel</th>
                <th scope="col">Time</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              { loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner size={45} color="primary" />
                </div>
              ) : transactions &&
                transactions.map((transaction, index) => {
                  return (
                    <tr>
                      <th scope="row">
                        <input type="checkbox" className="t-check" />
                        {transaction.user?.firstName}
                      </th>
                      <td><Naira>{transaction.amount}</Naira></td>
                      <td>{"..."}</td>
                      <td>{transaction.rentReliefId ? "Rent Relief" : transaction.propertyId ? transaction.property?.isForRent ? "Rent" : transaction.property?.isForSale ? "Buy": null : null}</td>
                      <td>Property Mattaz</td>
                      <td>Flutterwave</td>
                      <td>Card</td>
                      <td>{transaction.dateCreated}</td>
                      <td className="status s" />
                    </tr>
                  );
                })
              }
            
            </tbody>
          </table>
        </div>
        <div className="pm-space" />
        <div className="pagination">
          <div className="page-arrows">
            <i className="fal fa-angle-left" />
          </div>
          <div className="page-number">1</div>
          <div className="page-number nb">of 238</div>
          <div className="page-arrows">
            <i className="fal fa-angle-right" />
          </div>
        </div>
      </div>
    </div>
  );
}
