import React from "react";

export default function AdminPermission() {
	return (
		<div className="permision-tab-content mt-3">
			<div className="row">
				<div className="col-lg-6">
					<div className="admin-act">
						<span className="permision">View Analytics</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">Modify Analytics</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">View Users</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">Modify Users</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">View Transactions</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">View Cleaning</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">Modify Cleaning</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">View Rent</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">Modify Rent</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">View Verification</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">Modify Verification</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">View Payments</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">Modify Payments</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act wh">
						<span className="permision">View Admin</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="admin-act">
						<span className="permision">Modify Admin</span>
						<div className="switch my-0">
							<label className="ag-toggle">
								<input type="checkbox" defaultChecked />
								<span className="slider" />
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
