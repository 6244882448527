import React from "react";
import { useLocation } from "react-router-dom";
import { SideNav } from "../Navigations/SideNav";

import TopNav from "../Navigations/TopNav";

export default function Template({ children }: { children: any }) {
	const location = useLocation();

	return (
		<>
			{location.pathname === "/" ? (
				<>{children}</>
			) : (
				<>
					<SideNav />
					<div className="main-content">
						<TopNav />
						{children}
					</div>
				</>
			)}
		</>
	);
}
