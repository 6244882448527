import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { User } from "../../Classes/User";
import AdminSpinner from "../../Utilities/AdminSpinner";
import MiniModal from "../../Utilities/Modal/MiniModal";
import { UserView } from "../../Utilities/Types";
import UserList from "../Users/UserList";
import UserLoaded from "../Users/UserLoaded";
import AddAdmin from "./AdminModals/AddAdmin";
import AdminPermission from "./AdminPermission";
import AdminProfile from "./AdminProfile";
import AdminRep from "./AdminRep";

export default function AdminBody() {
	const [addAdmin, setAddAdmin] = useState(false);
	const [admins, setAdmins] = useState<UserView[]>();
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [selectedUser, setSelectedUser] = useState<UserView>();
	const [pagingDetails, setPagingDetails] = useState<any>();
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const [tabName, setTabName] = useState("profile");
	const { addToast } = useToasts();

	const list = async (url?: string) => {
		if (!url) {
			setLoading(true);
		}
		let data = await User.listAdmin(url);
		if (!data.status) {
			setLoading(false);
			addToast(data.message, { appearance: "error", autoDismiss: true });
			return;
		}
		setTotalPages(Math.floor(data.data.size / data.data.limit));
		setCurrentPage((data.data.limit + data.data.offset) / data.data.limit);
		setAdmins(data.data.value);
		setPagingDetails(data.data);
		setSelectedUser(data.data.value[0]);
		console.log(data);
		setLoading(false);
		return;
	};

	const searchUsers = async () => {
		const url = `?search=${search}`;
		list(url);
	};
	const updateSearchString = async (char: any) => {
		setSearch(char);
		await searchUsers();
	};

	useEffect(() => {
		const fetchInitialData = async () => {
			await list();
		};
		fetchInitialData();
	}, []);

	const deleteUser = async (email?: string) => {
		setLoading(true);
		try {
			let data = await User.deleteAdmin(email);
			if (data.status) {
				closeAddModal();
				await list();
				setLoading(false);
				addToast(data.message, { appearance: "success", autoDismiss: true });
				return;
			}
			addToast(data.message, { appearance: "error", autoDismiss: true });
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const paginate = async (direction: any) => {
		console.log({ pagingDetails });

		let url = "";
		if (direction == "next") {
			url = pagingDetails.next.href.split("list")[1];
			await list(url);
			return;
		}
		url = pagingDetails.previous.href.split("list")[1];
		await list(url);
		return;
	};

	const setUser = (id: any) => {
		let selectedUser = admins?.filter((record) => record.id == id)[0];
		setSelectedUser(selectedUser);
	};

	const closeAddModal = async () => {
		setAddAdmin(false);
		await list();
	};
	return loading ? (
		<AdminSpinner />
	) : (
		<>
			<MiniModal open={addAdmin} onClose={closeAddModal}>
				<AddAdmin close={closeAddModal} />
			</MiniModal>
			<div className="admin-inner">
				<div className="flex align-items-start divided">
					<UserList
						users={admins && admins.length > 0 ? admins : []}
						totalPages={totalPages}
						setUser={setUser}
						paginate={paginate}
						currentPage={currentPage}
						search={updateSearchString}
					/>
					<div className="user-content-box">
						<div className="flex">
							<UserLoaded users={selectedUser} listUsers={list} />
							<button className="primary-btn" onClick={() => setAddAdmin(true)}>
								Add Admin
							</button>
						</div>
						<div className="admin-tab">
							<div
								className={`single-admin-tab ${
									tabName == "profile" ? "current" : ""
								}`}
								onClick={() => setTabName("profile")}
							>
								Profile
							</div>
							<div
								className={`single-admin-tab ${
									tabName == "listings" ? "current" : ""
								}`}
								onClick={() => setTabName("listings")}
							>
								Permission
							</div>
							<div
								className={`single-admin-tab ${
									tabName == "settings" ? "current" : ""
								}`}
								onClick={() => setTabName("settings")}
							>
								Representative
							</div>
						</div>
						{tabName == "profile" ? (
							<AdminProfile user={selectedUser} deleteUser={deleteUser} />
						) : tabName == "listings" ? (
							<AdminPermission />
						) : (
							<AdminRep />
						)}
					</div>
				</div>
			</div>
		</>
	);
}
