import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TenancyView } from "../../Types/api";
// @ts-ignore
import Modal from "../../Utilities/Modal/Modal";
import Complaints from "./RentModals/Complaints";
//@ts-ignore
import Naira from 'react-naira';
import Moment from "react-moment";

export default function TenancyDetails() {
  const [openComplaint, setOpenComplaint] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { tenancy } = location.state as any;
  const [thisTenancy, setTenancy] = useState<TenancyView>(tenancy);

  const openComplaintModal = () => {
    setOpenComplaint(!openComplaint);
  };

  return (
    <>
      <Modal open={openComplaint} onClose={() => setOpenComplaint(false)}>
        <Complaints close={() => setOpenComplaint(false)} />
      </Modal>

      <div className="flex top my-3 mx-3">
        <div className="first-rent">
          <div className="single-rent-top">
            <div className="sale-type ten">Active Tenancy</div>
            <div className="flex pt-3">
              <div className="back-icon" onClick={() => history.goBack()}>
                <i className="fas fa-chevron-left" />
              </div>
              <div className="listing-full">{thisTenancy.property?.name}</div>
            </div>
          </div>
          <div className="single-rent-btm">
            <div className="list-tip my-2">Owner</div>
            <div className="pic-box mt-3" />
            <div className="per-details">
              <label className="tab-label">First Name</label>
              <span className="tab-text">{thisTenancy.owner?.firstName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Middle Name</label>
              <span className="tab-text">{thisTenancy.owner?.middleName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Surame</label>
              <span className="tab-text">{thisTenancy.owner?.lastName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Mobile Number</label>
              <span className="tab-text">{thisTenancy.owner?.phoneNumber}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Email</label>
              <span className="tab-text">{thisTenancy.owner?.email}</span>
            </div>
            <button className="btn-outline w-100">See Owner’s Details</button>
            <div className="list-tip">Remittance</div>
            <div className="per-details">
              <label className="tab-label">Frequency</label>
              <span className="tab-text">
                {thisTenancy.property?.rentCollectionType?.name}
              </span>
            </div>
            <div className="per-details">
              <label className="tab-label">Next Payment Date</label>
              <span className="tab-text"><Moment format={"DD/MM/YYYY"}>{thisTenancy.rentDueDate}</Moment></span>
            </div>
            <div className="per-details">
              <label className="tab-label">Bank</label>
              <span className="tab-text">{thisTenancy.owner?.bank}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Account Number</label>
              <span className="tab-text">{thisTenancy.owner?.accountNumber}</span>
            </div>
          </div>
        </div>
        <div className="second-rent">
          <div className="single-rent-top" />
          <div className="single-rent-btm">
            <div className="list-tip my-2">Tenant</div>
            <div className="pic-box mt-3" />
            <div className="per-details">
              <label className="tab-label">First Name</label>
              <span className="tab-text">{thisTenancy.tenant?.firstName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Middle Name</label>
              <span className="tab-text">{thisTenancy.tenant?.middleName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Surame</label>
              <span className="tab-text">{thisTenancy.tenant?.lastName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Mobile Number</label>
              <span className="tab-text">{thisTenancy.tenant?.phoneNumber}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Email</label>
              <span className="tab-text">{thisTenancy.tenant?.email}</span>
            </div>
            <button className="btn-outline w-100">See Tenant's Details</button>
          </div>
        </div>
        <div className="third-rent">
          <div className="single-rent-top flex justify-content-end">
            <i className="far fa-trash-alt rmf" />
          </div>
          <div className="single-rent-btm">
            <div className="list-tip my-2">Property</div>
            <div className="per-details">
              <label className="tab-label">Annual Rent</label>
              <span className="tab-text"><Naira>{thisTenancy.property?.price}</Naira></span>
            </div>
            <button className="rent-btn" onClick={() => openComplaintModal()}>
              <i className="fas fa-house-damage" />
              Complaints
            </button>
            <button className="rent-btn">
              <i className="fas fa-file-alt" />
              Tenancy Agreement
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
