import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PagedApiResponseData, PropertyView } from "../../Utilities/Types";
import Moment from "react-moment";
//@ts-ignore
import Naira from "react-naira";
import Modal from "../../Utilities/Modal/Modal";
import AddProperty from "./ListingModals/AddProperty";
import Pagination from "../../Utilities/Pagination";

export default function Listings({
	listings,
	search,
	list,
	pagingDetails
}: {
	listings: PropertyView[];
	search?: any;
	list: any;
	pagingDetails?: PagedApiResponseData
}) {
	let properties = listings;
	
	const [miniTab, setMiniTab] = useState("all");
	const [showAddProperty, setShowAddProperty] = useState(false);
	const setTab = (tabname: string) => {
		setMiniTab(tabname);
	};
	const closeProperty = async () => {
		setShowAddProperty(false);
		await list();
	};

	return (
		<>
			<Modal open={showAddProperty} onClose={async () => await closeProperty}>
				<AddProperty close={closeProperty} list={list} />
			</Modal>
			<div className="inner-wrapper">
				<div className="flex all-tabs">
					<div
						className={`mini-tabs ${miniTab == "all" ? "active" : ""}`}
						onClick={() => setTab("all")}
					>
						All
					</div>
					<div
						className={`mini-tabs ${miniTab == "drafts" ? "active" : ""}`}
						onClick={() => setTab("drafts")}
					>
						Drafts
					</div>
					<div
						className={`mini-tabs ${miniTab == "active" ? "active" : ""}`}
						onClick={() => setTab("active")}
					>
						Active
					</div>
				</div>

				<div className="flex-item mb-4">
					<div className="admin-search-bar-2">
						<i className="fas fa-search" />
						<input
							type="text"
							className="property-search"
							placeholder="Search"
							onChange={(e) => search(e.target.value)}
						/>
					</div>
					<button
						className="primary-btn"
						onClick={() => setShowAddProperty(true)}
					>
						Add Property
					</button>
				</div>
				{miniTab == "all" ? (
					<div className="table-box">
						<table className="table table-hover">
							<thead className="ag-thead">
								<tr>
									<th scope="col">Type</th>
									<th scope="col">Seller</th>
									<th scope="col">Location</th>
									<th scope="col">Locality</th>
									<th scope="col">Price</th>
									<th scope="col">Date Added</th>
									<th scope="col">Sale Type</th>
								</tr>
							</thead>
							<tbody>
								{properties.map((listing: any, i: any) => {
									return (
										<tr key={i}>
											<Link to={`/listings/listing/${listing.id}`}>
												<th scope="row">{listing.name}</th>
											</Link>
											<td className="user">
												{listing.createdByUser?.fullName}
											</td>
											<td>{listing.state}</td>
											<td>{listing.area}</td>
											<td>
												<Naira>{listing.price}</Naira>
											</td>
											<td>
												<Moment
													format="YYYY/MM/DD"
													date={new Date(listing.dateCreated)}
												/>
											</td>
											<td>{listing.sellMyself ? "Self Sale" : "PM"}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				) : miniTab == "drafts" ? (
					<div className="table-box">
						<table className="table table-hover">
							<thead className="ag-thead">
								<tr>
									<th scope="col">Type</th>
									<th scope="col">Seller</th>
									<th scope="col">Location</th>
									<th scope="col">Locality</th>
									<th scope="col">Price</th>
									<th scope="col">Date Added</th>
									<th scope="col">Sale Type</th>
								</tr>
							</thead>
							<tbody>
								{/* {listings.map((listing, i) => {
									return listing.isDraft ? (
										<tr key={i}>
											<Link to={`/listings/${listing.id}`}>
												<th scope="row">{listing.name}</th>
											</Link>
											<td className="user">
												{listing.createdByUser?.fullName}
											</td>
											<td>{listing.state}</td>
											<td>{listing.area}</td>
											<td>
												<Naira>{listing.price}</Naira>
											</td>
											<td>
												<Moment
													format="YYYY/MM/DD"
													date={new Date(listing?.dateCreated)}
												/>
											</td>
											<td>{listing.sellMyself ? "Self Sale" : "PM"}</td>
										</tr>
									) : (
										""
									);
								})} */}
							</tbody>
						</table>
					</div>
				) : (
					<div className="table-box">
						<table className="table table-hover">
							<thead className="ag-thead">
								<tr>
									<th scope="col">Type</th>
									<th scope="col">Seller</th>
									<th scope="col">Location</th>
									<th scope="col">Locality</th>
									<th scope="col">Price</th>
									<th scope="col">Date Added</th>
									<th scope="col">Sale Type</th>
								</tr>
							</thead>
							<tbody>
								{/* {listings.map((listing, i) => {
									return !listing.isDraft ? (
										<tr key={i}>
											<Link to={`/listings/${listing.id}`}>
												<th scope="row">{listing.name}</th>
											</Link>
											<td className="user">
												{listing.createdByUser?.fullName}
											</td>
											<td>{listing.state}</td>
											<td>{listing.area}</td>
											<td>
												<Naira>{listing.price}</Naira>
											</td>
											<td>
												<Moment
													format="YYYY/MM/DD"
													date={new Date(listing?.dateCreated)}
												/>
											</td>
											<td>{listing.sellMyself ? "Self Sale" : "PM"}</td>
										</tr>
									) : (
										""
									);
								})} */}
							</tbody>
						</table>
					</div>
				)}

				<div className="pm-space" />
				<Pagination details={pagingDetails} list={list} />
			</div>
		</>
	);
}
