import React from "react";
import { UserView } from "../../Utilities/Types";

export default function UserLoaded({
	users,
	listUsers,
}: {
	users?: UserView;
	listUsers?: any;
}) {
	return (
		<div className="flex-item divided">
			<div className="flex divided">
				<div className="user-avatar">
					{users?.medias && users?.medias.length > 0 ? (
						<img src={users.medias[0].url} alt="" />
					) : (
						`${users?.firstName?.charAt(0).toUpperCase()}${users?.lastName
							?.charAt(0)
							.toUpperCase()}`
					)}
				</div>
				<div className="username">
					<h5 className="user-name-a">{users?.fullName}</h5>
				</div>
			</div>
		</div>
	);
}
