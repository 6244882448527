import { Endpoints, ErrorMessages, Statuses } from "../Utilities/Enums";
import Fetch from "../Utilities/Fetch";
import { PagedApiResponse,Response } from "../Utilities/Types";

export class Cleaning {
    public static listRequests = async (url?: any) => {
		let response: Response = {
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
			data: null,
		};
		try {
			let data = (await Fetch(
				`${Endpoints.Admin.LIST_CLEAN_REQUESTS}${url ? url : ""}`
			)) as unknown as PagedApiResponse;
			let response: Response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			return response;
		} catch (error) {
			console.log(error);
			return response;
		}
	};
}