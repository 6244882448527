import React, { useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { PropertyView } from "../../Utilities/Types";
import parse from "html-react-parser";
//@ts-ignore
import Naira from "react-naira";
import { AllListings } from "../../Classes/AllListings";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import MiniModal from "../../Utilities/Modal/MiniModal";
import Spinner from "../../Utilities/Spinner";
import { Statuses } from "../../Utilities/Enums";

export default function SingleListing({
  property, isModal
}: {
  property?: PropertyView; isModal?: Boolean;
}) {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [propertyId, setPropertyId] = useState(0);
  let history = useHistory();
  const deleteProperty = async (propertyId: number) => {
    setLoading(true);
    try {
      let data = await AllListings.Delete(propertyId);
      if (data.status) {
        history.push("/listings");
        setLoading(false);
        addToast("Property Successfully Deleted", {
          appearance: "success",
          autoDismiss: true,
        });
        console.log(data.message);

        return;
      }
      addToast(data.message, { appearance: "error", autoDismiss: true });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const Approve = async (propertyId: number) => {
	  setApproving(true);
	  try {
		  const data = await AllListings.Approve(propertyId);
		  if (!data.status) {
			addToast(data.message, { appearance: "error", autoDismiss: true });
			setApproving(false);
			return;
		  }
		  addToast(data.message, { appearance: "success", autoDismiss: true });
		  setApproving(false);
		  return;
	  } catch (error) {
		addToast("An error occurred.", { appearance: "error", autoDismiss: true });
		setApproving(false);
		console.error(error);
		
	  }
  }
  const reject = async (propertyId: number) => {
	setRejecting(true);
	try {
		const data = await AllListings.Reject(propertyId);
		if (!data.status) {
		  addToast(data.message, { appearance: "error", autoDismiss: true });
		  setRejecting(false);
		  return;
		}
		addToast(data.message, { appearance: "success", autoDismiss: true });
		setRejecting(false);
		return;
	} catch (error) {
	  addToast("An error occurred.", { appearance: "error", autoDismiss: true });
	  console.error(error);
	  setRejecting(false);
	}
}
  return (
    <>
      <MiniModal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        height={"150px"}
        width={"30%"}
      >
        <div className="flex-column justify-content-center">
          <h5 className="text-center">
            Are you sure you want to delete <span>{property?.name} </span>
          </h5>
          <div className="row  flex-center justify-space-between mt-3">
            <button
              className="user-red-btn-a "
              style={{ backgroundColor: "#000", marginRight: "1rem" }}
              onClick={async () => await setDeleteModal(false)}
            >
              No
            </button>

            <button
              className="user-red-btn-a "
              onClick={async () => await deleteProperty(propertyId)}
            >
              {loading ? <Spinner /> : "Yes"}
            </button>
          </div>
        </div>
      </MiniModal>

      <div className={`single-listing-preview ${isModal ? 'w-100' : ''}`}>
        
          <div className="flex-item bdm">
            <button className="action-btn danger" onClick={() => reject(property?.id as number)} disabled={property?.status == Statuses.REJECTED ? true : false}>{rejecting ? "Rejecting..." : "Reject"}</button>
            <button className="action-btn success" onClick={() => Approve(property?.id as number)} disabled={property?.status == Statuses.VERIFIED ? true : false}>{approving ? "Accepting..." : "Accept"}</button>
            <button
              className="action-btn"
              onClick={() => {
                setPropertyId(property?.id ? property.id : 0);
                setDeleteModal(true);
              }}
            >
              <i className="fas fa-trash-alt" />
            </button>
          </div>

        <div className="listing-show">
          <div className="list-tip my-0">Preview</div>
          <div className="property-info-box w-100">
            <div className="property-img">
              <img
                src={
                  property?.mediaFiles && property?.mediaFiles.length >= 1
                    ? property.mediaFiles.find((r) => r.isImage)?.url
                    : "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg"
                }
                alt=""
              />
              <div className="listing-location">{property?.area}</div>
            </div>
            <div className="property-details-box">
              <div className="flex-item mb-4">
                <h6 className="property-box-title">{property?.name}</h6>
                <i
                  className={property?.verified ? "fas fa-badge-check b" : ""}
                />
              </div>
              <div className="flex-item cl">
                <p className="property-box-sub">
                  <i className="fal fa-bed" />{" "}
                  {`${property?.numberOfBedrooms} ${
                    property?.numberOfBedrooms && property?.numberOfBedrooms > 1
                      ? "Bedrooms"
                      : "Bedroom"
                  }`}
                </p>
                <p className="property-box-sub">
                  <i className="fal fa-toilet" />{" "}
                  {`${property?.numberOfBathrooms} ${
                    property?.numberOfBathrooms &&
                    property?.numberOfBathrooms > 1
                      ? "Bathrooms"
                      : "Bathroom"
                  }`}
                </p>
                <p className="property-box-sub">
                  <i className="fal fa-tags" />
                  <Naira> {property?.price} </Naira>
                </p>
                <p className="property-box-sub">
                  <i className="fal fa-award" /> {property?.title}
                </p>
              </div>
              <h6 className="desc-title">Description</h6>
              <ul className="desc">
                {parse(property?.description ? property.description : "")}
              </ul>
              <h6
                className={
                  property?.mediaFiles && property?.mediaFiles.length >= 1
                    ? "desc-title"
                    : "d-none"
                }
              >
                Pictures
              </h6>
              <SRLWrapper>
                <div className="flex admin-media-cover">
                  {property?.mediaFiles && property?.mediaFiles.length >= 1
                    ? property?.mediaFiles.map((file, index) => {
                        return file.isImage ? (
                          <img
                            src={file.url}
                            alt=""
                            className="admin-img-field"
                          />
                        ) : null;
                      })
                    : null}
                </div>
              </SRLWrapper>
              <h6
                className={
                  property?.mediaFiles && property?.mediaFiles.length >= 1
                    ? "desc-title"
                    : "d-none"
                }
              >
                Interactive 3D Tour
              </h6>
              <div className="flex admin-media-cover">
                {property?.mediaFiles && property?.mediaFiles.length >= 1
                  ? property?.mediaFiles.map((file, index) => {
                      return file.isVideo ? (
                        <div className="pic-tab w-100">
                          <video
                            className="admin-img-field"
                            src={file.url}
                            controls
                          />
                        </div>
                      ) : null;
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
