import React from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Utilities/Pagination";
import { PropertyView, UserEnquiries } from "../../Utilities/Types";
//@ts-ignore
import Naira from 'react-naira';
import { UserEnquiry } from "../../Types/api";

export default function Enquires({
	enquiries,
	search,
	list,
}: {
	enquiries: UserEnquiry[];
	search?: any;
	list: any;
}) {
	console.log({enquiries});

	return (
		<div className="inner-wrapper">
			<div className="flex-item mb-4">
				<div className="admin-search-bar-2">
					<i className="fas fa-search" />
					<input
						type="text"
						className="property-search"
						placeholder="Search"
						onChange={(e) => search(e.target.value)}
					/>
				</div>
			</div>
			<div className="table-box">
				<table className="table table-hover">
					<thead className="ag-thead">
						<tr>
							<th scope="col">Property Name</th>
							<th scope="col">User</th>
							<th scope="col">State</th>
							<th scope="col">Locality</th>
							<th scope="col">Area</th>
							<th scope="col">Inspection</th>
							<th scope="col">Status</th>
						</tr>
					</thead>
					<tbody>
						{enquiries && enquiries.map((enquiry, index) => {
							return <tr key={index}>
							<Link to={{ 
								pathname:"/listings/enquiry/details",
								state:{
									enquiry
								}
							}}>
								<th scope="row">{enquiry.property?.name}</th>
							</Link>
							<td className="user">{enquiry.user?.firstName}</td>
							<td>{enquiry.property?.state}</td>
							<td>{enquiry.property?.lga}</td>
							<td>{enquiry.property?.area ? enquiry.property?.area : "-"}</td>
							<td><Naira>{enquiry.property?.price}</Naira></td>

							<td
								className={`status ${
									enquiry.active
										? "p"
										:  "s"
								}`}
							></td>
						</tr>
						})}
						{/* {properties.map((listing: any, i: any) => {
							return (
								<tr key={i}>
									<Link to={`/listings/${listing.id}`}>
										<th scope="row">{listing.propertyType?.name}</th>
									</Link>
									<td className="user">{listing.user?.firstName}</td>
									<td>{listing.state}</td>
									<td>{listing.lga}</td>
									<td>{listing.area ? listing.area : "-"}</td>
									<td>{listing.budget}</td>

									<td
										className={`status ${
											listing.status && listing.status == "PENDING"
												? "p"
												: listing.status == "SUCCESSFUL"
												? "s"
												: "f"
										}`}
									></td>
								</tr>
							);
						})} */}
					</tbody>
				</table>
			</div>
			<div className="pm-space" />
			{/* <Pagination details={listings} list={list} /> */}
		</div>
	);
}
