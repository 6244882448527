import React from "react";
//@ts-ignore
import Naira from "react-naira"

export default function Tabs({ isActive, isClosed, isHelp }: {isActive?: Boolean, isClosed?: Boolean, isHelp?: Boolean}) {
    return (
        <div className="table-box">
            { ( isActive || isClosed ) ? (
                <table className="table table-hover">
                    <thead className="ag-thead">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Owner</th>
                            <th scope="col">Location</th>
                            <th scope="col">Locality</th>
                            <th scope="col">Rent</th>
                            <th scope="col">Date Added</th>
                            <th scope="col">Rental Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">4 Bedroom Terrace with Pool</th>
                            <td className="user">Pade Omotosho</td>
                            <td>Lagos</td>
                            <td>Lekki</td>
                            <td><Naira>40000000</Naira></td>
                            <td>22/06/21</td>
                            <td>Self-sale</td>
                        </tr>
                        <tr>
                            <th scope="row">4 Bedroom Terrace with Pool</th>
                            <td className="user">Pade Omotosho</td>
                            <td>Lagos</td>
                            <td>Lekki</td>
                            <td><Naira>40000000</Naira></td>
                            <td>22/06/21</td>
                            <td>PM</td>
                        </tr>
                        <tr>
                            <th scope="row">4 Bedroom Terrace with Pool</th>
                            <td className="user">Pade Omotosho</td>
                            <td>Lagos</td>
                            <td>Lekki</td>
                            <td><Naira>40000000</Naira></td>
                            <td>22/06/21</td>
                            <td>Self-sale</td>
                        </tr>
                        <tr>
                            <th scope="row">4 Bedroom Terrace with Pool</th>
                            <td className="user">Pade Omotosho</td>
                            <td>Lagos</td>
                            <td>Lekki</td>
                            <td><Naira>40000000</Naira></td>
                            <td>22/06/21</td>
                            <td>PM</td>
                        </tr>
                    </tbody>
                </table>
            )  : (
                <table className="table table-hover">
                    <thead className="ag-thead">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Owner</th>
                            <th scope="col">Location</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">Inspection</th>
                            <th scope="col">Interest</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">4 Bedroom Terrace with Pool</th>
                            <td className="user">Pade Omotosho</td>
                            <td>Lagos</td>
                            <td>09012345678</td>
                            <td>kaska@gmail.com</td>
                            <td>22/06/21</td>
                            <td>Owner</td>
                        </tr>
                        <tr>
                            <th scope="row">4 Bedroom Terrace with Pool</th>
                            <td className="user">Pade Omotosho</td>
                            <td>Lagos</td>
                            <td>09012345678</td>
                            <td>kaska@gmail.com</td>
                            <td>22/06/21</td>
                            <td>Owner</td>
                        </tr>
                    </tbody>
                </table>
            )
            }
		</div>
    )
}