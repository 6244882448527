import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const SideNav = () => {
	const location = useLocation();
	const getNavLinks = (name: string) => {
		if (location.pathname.startsWith(name)) return "active";
	};

	return (
		<div className="side-nav-content">
			<div className="admin-logo">
				<img src="/assets/@2xGroup 5-3.png" alt="" />
			</div>
			<ul className="sides">
				<li className={`side-link ${getNavLinks("/dashboard")}`}>
					<i className="far fa-analytics" />
					<Link to="/dashboard">Dashboard</Link>
				</li>
				<li className={`side-link ${getNavLinks("/users")}`}>
					<i className="far fa-users" />
					<Link to="/users">Users</Link>
				</li>
				<li className={`side-link ${getNavLinks("/transactions")}`}>
					<i className="far fa-history" />
					<Link to="/transactions">Transactions</Link>
				</li>
				<li className={`side-link ${getNavLinks("/listings")}`}>
					<i className="far fa-building" />
					<Link to="/listings">Listings</Link>
				</li>
				<li className={`side-link ${getNavLinks("/cleanings")}`}>
					<i className="far fa-vacuum" />
					<Link to="/cleanings">Cleanings</Link>
				</li>
				<li className={`side-link ${getNavLinks("/rent")}`}>
					<i className="far fa-house-user" />
					<Link to="/rent">Rent</Link>
				</li>
				<li className={`side-link ${getNavLinks("/relief")}`}>
					<i className="far fa-percentage" />
					<Link to="/relief">Rent Relief</Link>
				</li>
				<li className={`side-link ${getNavLinks("/verification")}`}>
					<i className="far fa-stamp" />
					<Link to="/verification">Verification</Link>
				</li>
				<li className={`side-link ${getNavLinks("/payments")}`}>
					<i className="far fa-money-bill" />
					<Link to="/payments">Payments</Link>
				</li>
				<li className={`side-link ${getNavLinks("/admin")}`}>
					<i className="far fa-cog" />
					<Link to="/admin">Admin</Link>
				</li>
			</ul>
		</div>
	);
};
