import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { InspectionTimeModel } from "../../../Types/api";
import { Properties } from "../../../Classes/Properties";
import { useToasts } from "react-toast-notifications";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { StaticTimePicker } from '@mui/lab';
import moment from "moment";
import { TextField } from "@mui/material";
export default function AddTimeSlotModal({
  close,
  dateId,
}: {
  close: any;
  dateId: number;
}) {
  const { addToast } = useToasts();
  const [time, setTime] = useState<InspectionTimeModel>({
    inspectionDateId: dateId,
  });

  const addNewTime = async () => {
    try {
      const data = await Properties.addInspectionTime(time);
      if (!data.status) {
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      addToast(data.message, { appearance: "success", autoDismiss: true });
      close();
    } catch (error) {
      console.error(error);
      return;
    }
  };

  function onChange(value: any) {
    console.log(value && value.format("h:mm a"));
  }

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <div className="">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              ampm
              orientation="landscape"
              openTo="minutes"
              value={time.availableTime}
              onChange={(newValue) => {
                setTime({...time,availableTime:newValue as string});
              }}
              renderInput={(params:any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="mt-5">
          <button className="btn-outline" onClick={() => addNewTime()}>
            Add Time
          </button>
        </div>
      </div>
    </div>
  );
}
