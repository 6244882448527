import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AllListings } from "../../Classes/AllListings";
import { PropertyView } from "../../Utilities/Types";
import EditListing from "./ListingModals/EditListing";
import SingleListing from "./SingleListing";

type PropertyParams = {
	id: string;
};
export default function ListingDetails() {
	const { id } = useParams<PropertyParams>();
	const [property, setProperty] = useState<PropertyView>();
	let history = useHistory();
	const getProperty = async () => {
		let data = await AllListings.GetById(parseInt(id));
		if (!data.status) {
			return;
		}
		setProperty(data.data);
	};

	useEffect(() => {
		const getInitialData = async () => {
			await getProperty();
		};
		getInitialData();
	}, []);
	return (
		<div className="flex top">
			<div className="single-listing-info">
				{/* Add class PM to sale-type if Property Mattaz */}
				<div className={`sale-type ${property?.sellMyself ? "" : "pm"}`}>
					{property?.sellMyself ? "Self-sale" : "PropertyMataaz"}
				</div>
				<div className="flex pt-3">
					<div
						className="back-icon"
						onClick={() => history.push("/listings")}
					>
						<i className="fas fa-chevron-left" />
					</div>
					<div className="listing-full">{property?.name}</div>
				</div>
				<EditListing property={property} />
			</div>
			<SingleListing property={property} />
		</div>
	);
}
