import React from "react";
import Moment from "react-moment";
import { ApplicationView } from "../../Types/api";
import Pagination from "../../Utilities/Pagination";
import { PagedApiResponseData, PropertyView } from "../../Utilities/Types";
//@ts-ignore
import Naira from "react-naira";

export default function RentLists({
  listings,
  search,
  pagingDetails,
  paginationCallBack,
}: {
  listings?: ApplicationView[];
  search?: any;
  pagingDetails?: PagedApiResponseData;
  paginationCallBack?: any;
}) {
  return (
    <div className="inner-wrapper">
      <div className="flex-item mb-4">
        <div className="admin-search-bar-2">
          <i className="fas fa-search" />
          <input type="text" className="property-search" placeholder="Search" />
        </div>
      </div>
      <div className="table-box">
        <table className="table table-hover">
          <thead className="ag-thead">
            <tr>
              <th scope="col">Property</th>
              <th scope="col">Landlord</th>
              <th scope="col">Applicant</th>
              <th scope="col">Annual Rent</th>
              <th scope="col">Date Applied</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {listings?.map((application, index) => {
              return (
                <tr>
                  <th scope="row">{application.property?.name}</th>
                  <td className="user">{application.property?.createdByUser?.fullName}</td>
                  <td className="user">{application.user?.fullName}</td>
                  <td><Naira>{application.property?.price}</Naira></td>
                  <td><Moment format="DD/MM/YY">{application.dateCreated}</Moment></td>
                  <td>Pending</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pm-space" />
      <Pagination list={paginationCallBack} details={pagingDetails} />
    </div>
  );
}
