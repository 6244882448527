import React, { useEffect, useState } from "react";
import RentLists from "./RentLists";
import ForRentTab from "./ForRentTab";
import Tenancies from "./Tenancies";
import {
  PagedApiResponseData,
  PropertyView,
  Response,
} from "../../Utilities/Types";
import { AllListings } from "../../Classes/AllListings";
import { useToasts } from "react-toast-notifications";
import { Tenancy } from "../../Classes/Tenancy";
import { TenancyView } from "../../Types/api/tenancy-view";
import { Applications } from "../../Classes/Applications";
import { ApplicationView } from "../../Types/api";

export default function RentBody() {
  const [loading, setLoading] = useState<boolean>();
  const { addToast } = useToasts();
  const [search, setSearch] = useState("");
  const [applications, setApplications] = useState<ApplicationView[]>();
  const [applicationResponse, setApplicationResponse] =
    useState<PagedApiResponseData>();
  const [forRents, setForRents] = useState<PropertyView[]>();
  const [forRentsResponse, setForRentsResponse] =
    useState<PagedApiResponseData>();
  const [tenancies, setTenancies] = useState<TenancyView[]>();
  const [tenanciesResponse, setTenanciesResponse] =
    useState<PagedApiResponseData>();

  const listApplications = async (url?: any) => {
    console.log({ url });
    if (!url) {
      setLoading(true);
    }
    let data = await Applications.listRentApplications(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setApplications(data.data.value);
    setApplicationResponse(data.data);

    setLoading(false);
    return;
  };

  const listTenancies = async (url?: any) => {
    console.log({ url });
    if (!url) {
      setLoading(true);
    }
    let data = await Tenancy.allTenancies(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setTenancies(data.data.value);
    setTenanciesResponse(data.data);

    setLoading(false);
    return;
  };

  const listRentProperties = async (url?: any) => {
    console.log({ url });

    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allPendingRentListings(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setForRents(data.data.value);
    setForRentsResponse(data.data);

    setLoading(false);
    return;
  };

  const updateSearchString = async (char: any) => {
    setSearch(char);

    await searchProperties();
  };

  const searchProperties = async () => {
    const url = `?search=${search}`;
    tab === "applications"
      ? await listApplications(url)
      : tab === "for rent"
      ? await listRentProperties(url)
      : await listTenancies(url);
  };

  useEffect(() => {
    const displayListings = async () => {
      await listApplications();
      await listRentProperties();
      await listTenancies();
    };
    displayListings();
  }, []);

  const [tab, setTab] = useState("applications");

  const setCurrentTab = (tabname: string) => {
    setTab(tabname);
  };

  return (
    <div className="admin-inner">
      <div className="flex">
        <div
          className={`pm-tab ${tab == "applications" ? "active" : ""}`}
          onClick={() => setCurrentTab("applications")}
        >
          <div className="flex align-items-center">
            <i className="fas fa-arrow-down" />
            <span>Applications</span>
          </div>
          <div className="pm-tab-count">{applicationResponse?.size}</div>
        </div>
        <div
          className={`pm-tab ${tab == "for-rent" ? "active" : ""}`}
          onClick={() => setCurrentTab("for-rent")}
        >
          <div className="flex align-items-center">
            <i className="far fa-house-leave" />
            <span>For Rent</span>
          </div>
          <div className="pm-tab-count">{forRentsResponse?.size}</div>
        </div>
        <div
          className={`pm-tab ${tab == "tenancies" ? "active" : ""}`}
          onClick={() => setCurrentTab("tenancies")}
        >
          <div className="flex align-items-center">
            <i className="far fa-house-user" />
            <span>Tenancies</span>
          </div>
          <div className="pm-tab-count">{tenanciesResponse?.size}</div>
        </div>
      </div>

      {tab === "applications" ? (
        <RentLists
          listings={applications}
          paginationCallBack={listApplications}
          pagingDetails={applicationResponse}
          search={updateSearchString}
        />
      ) : tab == "for-rent" ? (
        <ForRentTab />
      ) : (
        <Tenancies
          tenancies={tenancies}
          paginationCallBack={listTenancies}
          pagingDetails={tenanciesResponse}
          search={updateSearchString}
        />
      )}
    </div>
  );
}
