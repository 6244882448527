import styled from "styled-components";

export const ModalWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	margin: auto;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	border-radius: 10px;
	width: max-content;
	min-width: 65%;
	overflow-y: auto;
	height: 100%;
	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const MiniWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	margin: auto;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	border-radius: 10px;
	width: max-content;
	overflow-y: auto;
	height: fit-content;
	@media (max-width: 768px) {
		width: 100%;
	}
`;
