import {
	Endpoints,
	Statuses,
	HttpMethods,
	ErrorMessages,
} from "../Utilities/Enums";
import Fetch from "../Utilities/Fetch";
import {
	ApiResponse,
	LoginModel,
	PagedApiResponse,
	Response,
	UserInput,
	UserView,
} from "../Utilities/Types";

export class User {
	_userView?: UserView;
	constructor(public email?: string, public id?: number) {}

	public Login = async (details: LoginModel) => {
		let response: Response;
		try {
			let data = (await Fetch(
				Endpoints.User.LOGIN,
				HttpMethods.POST,
				details
			)) as unknown as ApiResponse;
			response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			if (data.status) {
				this._userView = data.data as UserView;
			}
			return response;
		} catch (error) {
			console.log(error);
		}
	};
	public static userCount = async () => {
		let response: Response = {
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
			data: null,
		};
		try {
			let data = (await Fetch(
				Endpoints.Admin.METRICS
			)) as unknown as ApiResponse;
			let response: Response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			return response;
		} catch (error) {
			console.log(error);
			return response;
		}
	};
	public static listUsers = async (url?: any) => {
		let response: Response = {
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
			data: null,
		};
		try {
			let data = (await Fetch(
				`${Endpoints.User.LIST.All}${url ? url : ""}`
			)) as unknown as PagedApiResponse;
			let response: Response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			return response;
		} catch (error) {
			console.log(error);
			return response;
		}
	};
	public static userListProperty = async () => {
		let response: Response = {
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
			data: null,
		};
		try {
			let data = (await Fetch(
				Endpoints.User.LISTINGS.List
			)) as unknown as ApiResponse;

			let response: Response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			return response;
		} catch (error) {
			console.log(error);
			return response;
		}
	};
	public static AddAdmin = async (details: UserInput) => {
		let response: Response = {
			data: null,
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
		};
		try {
			let data = (await Fetch(
				`${Endpoints.Admin.CREATE}`,
				HttpMethods.POST,
				details
			)) as unknown as ApiResponse;
			let response: Response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			return response;
		} catch (error) {
			console.log(error);
			return response;
		}
	};
	public static listAdmin = async (url?: any) => {
		let response: Response = {
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
			data: null,
		};
		try {
			let data = (await Fetch(
				`${Endpoints.Admin.LIST}${url ? url : ""}`
			)) as unknown as PagedApiResponse;
			response = {
				status: data.status,
				message: data.message,
				data: data.data,
			};
			return response;
		} catch (error) {
			console.log(error);
			return response;
		}
	};
	public static deleteAdmin = async (email?: string) => {
		let response: Response = {
			status: Statuses.FALSE,
			message: ErrorMessages.FAILED,
			data: null,
		};
		try {
			let data = (await Fetch(
				`${Endpoints.Admin.DELETE}/${email}`,
				HttpMethods.GET
			)) as unknown as ApiResponse;
			response.status = data.status;
			response.message = data.message;
			response.data = data.data;
			return response;
		} catch (error) {
			console.error(error);
			return response;
		}
	};
}
