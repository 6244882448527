import React, { useState } from "react";
import { PagedApiResponseData } from "./Types";

export default function Pagination({
	details,
	list,
}: {
	details?: PagedApiResponseData;
	list?: any;
}) {
	// console.log({list});
	
	const [totalPages, setTotalPages] = useState(
		Math.ceil((details?.size as number) / (details?.limit as unknown as number))
	);
	const [pagingDetails, setPagingDetails] = useState<any>(details);
	console.log(details);
	const [currentPage, setCurrentPage] = useState(
		((details?.limit as unknown as number )+ (details?.offset as unknown as number)) / (details?.limit as unknown as number)
	);

	const paginate = async (direction: any) => {
		let url = "";

		if (direction == "next") {
			url =  `?${(details?.next?.href?.split("?")[1] as string)}`;
			await list(url);
			return;
		}
		url = `?${(details?.previous?.href?.split("?")[1] as string)}`;
		await list(url);
		return;
	};


	return (
		<div className="pagination">
			<div
				className={`page-arrows ${totalPages == 0 ? "disabled" : ""}`}
				onClick={() => {
					paginate("previous");
				}}
			>
				<i className="fal fa-angle-left" />
			</div>
			<div className="page-number">{currentPage}</div>
			<div className="page-number nb">{`of ${
				totalPages ? totalPages : 1
			}`}</div>
			<div
				className={`page-arrows ${totalPages == 0 ? "disabled" : ""}`}
				onClick={() => {
					paginate("next");
				}}
			>
				<i className="fal fa-angle-right" />
			</div>
		</div>
	);
}
