import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { User } from "../../../Classes/User";
import Spinner from "../../../Utilities/Spinner";
import { UserInput } from "../../../Utilities/Types";

export default function AddAdmin({ close }: { close: any }) {
	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
	const initialValues: UserInput = {
		firstName: "",
		lastName: "",
		email: "",
	};
	const createAdmin = async (details: UserInput) => {
		setLoading(true);
		let data = await User.AddAdmin(details);
		if (data.status) {
			setLoading(false);
			addToast(data.message, { appearance: "success", autoDismiss: true });
			close();
			return;
		}
		setLoading(false);
		addToast(data.message, { appearance: "error", autoDismiss: true });
		return;
	};
	return (
		<div>
			<div className="flex-item">
				<div className="item-page">Add a New Admin</div>
				<i className="fal fa-times cancel" onClick={close} />
			</div>
			<Formik
				initialValues={initialValues}
				onSubmit={async (
					values: UserInput,
					{ setSubmitting }: FormikHelpers<UserInput>
				) => {
					console.log(values);
					await createAdmin(values);
				}}
			>
				<Form className="mt-5">
					<div className="input-box">
						<div className="input-label">First Name</div>
						<Field id="firstName" name="firstName" className="formfield" />
					</div>
					<div className="input-box">
						<div className="input-label">Surname</div>
						<Field id="lastName" name="lastName" className="formfield" />
					</div>
					<div className="input-box">
						<div className="input-label">Email</div>
						<Field id="email" name="email" className="formfield" />
					</div>
					<button className="secondary-btn" type="submit">
						{loading ? <Spinner /> : "Ok"}
					</button>
				</Form>
			</Formik>
		</div>
	);
}
function addToast(
	message: string,
	arg1: { appearance: string; autoDismiss: boolean }
) {
	throw new Error("Function not implemented.");
}
