export const Statuses = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  ONGOING: "ONGOING",
  RESOLVED: "RESOLVED",
  VERIFIED: "VERIFIED",
  DRAFTED: "DRAFTED",
  ONSALE: "ONSALE",
  SOLD: "SOLD",
  DRAFT: "DRAFT",
  REJECTED: "REJECTED",
  TRUE: true,
  FALSE: false,
};

export const HttpMethods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const ErrorMessages = {
  FAILED: "An Error occurred, Please try again later",
};

export const Endpoints = {
  User: {
    LOGIN: "admin/token",
    DELETE: "user/delete",
    LIST: {
      All: "user/list",
    },
    LISTINGS: {
      List: "property/user/created",
      Enquiry: "",
    },
  },
  Admin: {
    LOGIN: "admin/token",
    CREATE: "admin/create",
    LIST: "admin/list",
    METRICS: "admin/metrics",
    DELETE: "admin/delete",
    LIST_TRANSACTIONS: "admin/transactions/list",
    LIST_CLEAN_REQUESTS: "admin/clean/requests/list",
    LIST_PENDING_RELIEF_APPLICATION: "admin/applications/reliefs/pending",
    LIST_ACCEPTED_RELIEF_APPLICATION: "admin/applications/reliefs/accepted",
    LIST_REVIEWED_RELIEF_APPLICATION: "admin/applications/reliefs/reviewed",
    LIST_APPROVED_RELIEF_APPLICATION: "admin/applications/reliefs/approved",
    APPROVE_APPLICATION: "application/approve/",
    ACCEPT_APPLICATION: "application/accept/",
    REVIEW_APPLICATION: "application/review/",
    REJECT_APPLICATION: "application/reject/",
    ALL_TENANCIES: "admin/tenancies",
    LIST_RENT_APPLICATIONS: "admin/applications/rent",
    LIST_INSPECTION_DATES: "Property/inspectiondates/list/",
    ADD_INSPECTION_DATES: "Property/inspectiondates/create",
    ADD_INSPECTION_TIME: "Property/inspectiontime/create",
  },
  Listings: {
    AllListings: "admin/properties/list",
    PENDING_RENT: "admin/properties/list/rent/pending",
    RENT: "admin/properties/list/rent",
    GET: "property/get/",
    Requests: "admin/requests/list",
    GetRequest: "admin/requests/get/",
    Enquires: "admin/enquiries/list",
    Create: "admin/property/create",
    UpdateListing: "property/update",
    DeleteMedia: "",
    DeleteProperty: "property/delete/",
    ListTypes: "Property/types",
    APPROVE: "admin/property/approve/",
    REJECT: "admin/property/reject/",
  },
};
