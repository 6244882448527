import React from "react";

export default function AdminRep() {
	return (
		<div className="Rep-tab-content">
			<div className="row mt-4">
				<div className="col-lg-4">
					<div className="rep-image">
						<div className="rep-profile-img" />
						<div className="small-note">Profile Picture</div>
					</div>
					<div className="per-details">
						<label className="tab-label">First Name</label>
						<span className="tab-text">Pade</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Surname</label>
						<span className="tab-text">Omotoso</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Email</label>
						<span className="tab-text">padeomotoso@gmail.com</span>
					</div>
					<button className="btn-outline">Remove Rep</button>
				</div>
			</div>
		</div>
	);
}
