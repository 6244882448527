import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { TenancyView } from "../../Types/api";
import Pagination from "../../Utilities/Pagination";
import { PagedApiResponseData } from "../../Utilities/Types";
// @ts-ignore
import Naira from 'react-naira';

export default function Tenancies({
  tenancies,
  search,
  pagingDetails,
  paginationCallBack,
}: {
  tenancies?: TenancyView[];
  search?: any;
  pagingDetails?: PagedApiResponseData;
  paginationCallBack?: any;
}) {
  return (
    <div className="inner-wrapper">
      <div className="flex-item mb-4 mt-3">
        <div className="admin-search-bar-2">
          <i className="fas fa-search" />
          <input type="text" className="property-search" placeholder="Search" />
        </div>
      </div>
      <div className="table-box">
        <table className="table table-hover">
          <thead className="ag-thead">
            <tr>
              <th scope="col">Property</th>
              <th scope="col">Landlord</th>
              <th scope="col">Tenant</th>
              <th scope="col">Rent</th>
              <th scope="col">Next Payment</th>
            </tr>
          </thead>
          <tbody>
            {tenancies?.map((tenancy, index) => {
              return (
                <tr>
                  <Link
                    to={{
                      pathname: "/rent/tenancy/details",
                      state: {
                        tenancy,
                      },
                    }}
                  >
                    <th scope="row">{tenancy.property?.name}</th>
                  </Link>
                  <td className="user">{`${tenancy.owner?.firstName} ${tenancy.owner?.lastName}`}</td>
                  <td className="user">{`${tenancy.tenant?.firstName} ${tenancy.tenant?.lastName}`}</td>
                  <td><Naira></Naira>{tenancy.property?.price}</td>
                  <td>
                    <Moment format="DD/MM/YY">{tenancy.rentDueDate}</Moment>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pm-space" />
      <Pagination details={pagingDetails} list={paginationCallBack} />
    </div>
  );
}
