import React, { useCallback, useEffect, useState } from "react";
import { ToastProvider } from "react-toast-notifications";
import { MainContext } from "../../Contexts/MainContext";
import ROUTES, { RenderRoutes } from "../../Routes/Routes";
import { UserView } from "../../Utilities/Types";
import Template from "../Template/Template";
import "./App.css";

function App() {
	const [data, setData] = useState({});

	const setUser = useCallback(
		(user?: UserView) => {
			localStorage.setItem("user", JSON.stringify(user));
			setData({...data,user:user})
		},
		[data, setData]
	);
	useEffect(() => {
		localStorage.getItem("user")
			? setUser(JSON.parse(localStorage.getItem("user") || "{}"))
			: localStorage.setItem("", "");
	}, []);

	return (
		<>
			<MainContext.Provider value={{ data, setUser }}>
				<Template>
					<ToastProvider>
						<RenderRoutes routes={ROUTES} />
					</ToastProvider>
				</Template>
			</MainContext.Provider>
		</>
	);
}

export default App;
