import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { MainContext } from "../../Contexts/MainContext";


export default function TopNav() {
	// const dropdownRef = useRef()
	const { user } = useContext(MainContext).data;
	const location = useLocation();
	const history = useHistory();
	const [pageTitle, setPageTitle] = useState("");
	const [dropdown, setDropdown] = useState(false);
	
	// const handleDropdown = (e: any) => {
	// 	if(dropdownRef.current == e.target) {
	// 		setDropdown(!dropdown)
	// 		return
	// 	}
	// 	setDropdown(!dropdown)
	// }

	useEffect(() => {
		location.pathname.startsWith("/dashboard")
			? setPageTitle("Dashboard")
			: location.pathname.startsWith("/users")
			? setPageTitle("Users")
			: location.pathname.startsWith("/transactions")
			? setPageTitle("Transactions")
			: location.pathname.startsWith("/listings")
			? setPageTitle("Listings")
			: location.pathname.startsWith("/cleaning")
			? setPageTitle("Cleaning")
			: location.pathname.startsWith("/rent")
			? setPageTitle("Rent")
			: location.pathname.startsWith("/relief")
			? setPageTitle("Rent Relief")
			: location.pathname.startsWith("/verification")
			? setPageTitle("Verification")
			: location.pathname.startsWith("/payments")
			? setPageTitle("Payments")
			: location.pathname.startsWith("/admin")
			? setPageTitle("Admin")
			: setPageTitle("null");
	});
	return (
		<div className="top-nav">
			<div className="admin-container">
				<div className="top-nav-content">
					<h3 className="pm-page">{pageTitle}</h3>
					<div className="dropp" onClick={() => setDropdown(!dropdown)}>
						<div className="user-nav">
							<span className="user-greetings">{`Hi ${user?.firstName}`}</span>
							<div className="user-avatar">
								{user?.medias && user?.medias.length > 0 ? (
									<img src={user.medias[0].url} alt="" />
								) : (
									`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName
										?.charAt(0)
										.toUpperCase()}`
								)}
							</div>
							<i className="fal fa-angle-down" />
						</div>
						{/* Drop-down  */}
						<div className={`drop-down ${dropdown ? "active" : ""}`}>
							<ul>
								<li>
									<a href="#">Notifications</a>
								</li>
								<li
									onClick={() => {
										localStorage.clear();
										history.push("/");
									}}
								>
									Log out
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
