import React, { useState } from "react";
//@ts-ignore
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useToasts } from "react-toast-notifications";
import { Properties } from "../../../Classes/Properties";
import { InspectionDateModel } from "../../../Types/api";

export default function ScheduleInspectDateModal({propertyId,close}: { propertyId?: number,close:any }) {
    const  {addToast} = useToasts();

    const [inspectionDate,setInspectionDate] = useState<InspectionDateModel>({propertyId:propertyId});

    const addNewDate = async() => {
      console.log(inspectionDate);
      
        try {
            const data = await Properties.addInspectionDates(inspectionDate);
            if(!data.status){
                addToast(data.message, { appearance: "error", autoDismiss: true });
                return;
            }
            addToast(data.message, { appearance: "success", autoDismiss: true });
            close();
        } catch (error) {
            console.error(error);
            return;
        }
    }
  return (
    <div className="d-flex flex-column align-items-center">
        <div className="">
          <Calendar date={inspectionDate?.date} onChange={(date:any) => setInspectionDate({...inspectionDate,date: date})}/>
        </div>
        <div className="">
          <button className="btn-outline" onClick={() => addNewDate()}>Add Date</button>
        </div>
    </div>
  );
}
