import React from "react";
import { Route, Switch } from "react-router";
import AdminBody from "../Pages/Admin/AdminBody";
import LogIn from "../Pages/Authentication/LogIn";
import CleaningBody from "../Pages/Cleaning/CleaningBody";
import CleaningDetails from "../Pages/Cleaning/CleaningDetails";
import CleaningList from "../Pages/Cleaning/CleaningList";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ListingBody from "../Pages/Listings/ListingBody";
import ListingDetails from "../Pages/Listings/ListingDetails";
import Matches from "../Pages/Listings/Matches";
import OnList from "../Pages/Listings/OnList";
import Transactions from "../Pages/Transactions/Transactions";
import UserBody from "../Pages/Users/UserBody";
import RentReliefBody from "../Pages/RentRelief/RentReliefBody";
import RentBody from "../Pages/Rent/RentBody";
import Payments from "../Pages/Payments/Payments";
import RentDetails from "../Pages/Rent/RentDetails";
import ApplicationDetails from "../Pages/Rent/ApplicationDetails";
import { IRoutes } from "../Utilities/Types";
import TenancyDetails from "../Pages/Rent/TenancyDetails";
import EnquiryDetails from "../Pages/Listings/EnquiryDetails";

const ROUTES: IRoutes[] = [
	{ path: "/", key: "ROOT", exact: true, component: () => <LogIn /> },
	{
		path: "/dashboard",
		key: "ROOT",
		exact: true,
		component: () => <Dashboard />,
	},
	{
		path: "/users",
		key: "ROOT",
		exact: true,
		component: () => <UserBody />,
	},
	{
		path: "/transactions",
		key: "ROOT",
		exact: true,
		component: () => <Transactions />,
	},
	{
		path: "/listings",
		key: "ROOT",
		exact: true,
		component: () => <ListingBody />,
	},
	{
		path: "/listings/listing",
		key: "ROOT",
		exact: true,
		component: () => <OnList />,
	},
	{
		path: "/listings/listing/:id",
		key: "ROOT",
		exact: true,
		component: () => <ListingDetails />,
	},
	{
		path: "/listings/enquiry/details",
		key: "ROOT",
		exact: true,
		component: () => <EnquiryDetails />,
	},
	{
		path: "/listings/request/:id",
		key: "ROOT",
		exact: true,
		component: () => <Matches />,
	},
	{
		path:"/cleanings",
		key: "CLEAN",
		exact: true,
		component: () => <CleaningBody />
	},
	{
		path:"/cleanings/:id",
		key: "CLEAN",
		exact: true,
		component: () => <CleaningDetails />,
	},
	{
		path:"/rent",
		key: "RENT",
		exact: true,
		component: () => <RentBody />,
	},
	{
		path:"/rent/details",
		key: "RENT",
		exact: true,
		component: () => <RentDetails />,
	},
	{
		path:"/rent/application/details",
		key: "RENT",
		exact: true,
		component: () => <ApplicationDetails />,
	},
	{
		path:"/rent/tenancy/details",
		key: "RENT",
		exact: true,
		component: () => <TenancyDetails />,
	},
	{
		path:"/relief",
		key: "RELIEF",
		exact: true,
		component: () => <RentReliefBody />,
	},
	{
		path:"/payments",
		key: "PAYMENT",
		exact: true,
		component: () => <Payments />,
	},
	{ path: "/admin", key: "ROOT", exact: true, component: () => <AdminBody /> },
];
export default ROUTES;

function RoutesWithSubRoutes(route: IRoutes) {
	return (
		<Route
			path={route.path}
			exact={route.exact}
			render={(props) => <route.component {...props} routes={route.routes} />}
		/>
	);
}
export function RenderRoutes({ routes }: { routes: IRoutes[] }) {
	return (
		<Switch>
			{routes.map((route, i) => {
				return <RoutesWithSubRoutes {...route} />;
			})}
			<Route component={() => <h1>No Content Here!</h1>} />
		</Switch>
	);
}
