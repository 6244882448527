import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Properties } from "../../Classes/Properties";
import {
  InspectionDate,
  InspectionDateModel,
  InspectionDateView,
  InspectionModel,
  UserEnquiry,
} from "../../Types/api";
import MiniModal from "../../Utilities/Modal/MiniModal";
import AddTimeSlotModal from "./ListingModals/AddTimeSlotModal";
import ScheduleInspectDateModal from "./ListingModals/ScheduleInspectDateModal";

export default function EnquiryDetails() {
  const [addDateModalOpen, setAddDateModalOpen] = useState<boolean>();
  const [addTimeModalOpen, setAddTimeModalOpen] = useState<boolean>();
  const location = useLocation();
  const history = useHistory();
  const enquiry = location.state as any;
  const [thisEnquiry, setThisEnquiry] = useState<UserEnquiry>(enquiry.enquiry);
  const [inspectionDates, setInspectionDates] = useState<InspectionDateView[]>();
  const [currentDateId, setCurrentDate] = useState<number>(0);
  const [newInspectionDate, setNewInspectionsDate] =
    useState<InspectionDateModel>();
  const { addToast } = useToasts();

  console.log(enquiry);

  const closeModals = async () => {
    setAddDateModalOpen(false);
	setAddTimeModalOpen(false);
    await listInspectionDates();
  };

  const listInspectionDates = async () => {
    let data = await Properties.listInspectionDates(thisEnquiry.propertyId);
    if (!data?.status) {
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setInspectionDates(data.data);
    console.log(data.data);

    return;
  };

  useEffect(() => {
    const get = async () => {
      await listInspectionDates();
    };
    get();
  }, []);

  const openTimeSlotModal = (id?: number) => {
    setCurrentDate(id as number);
	setAddTimeModalOpen(true);
  };

  return (
    <div className="admin-inner">
      <MiniModal
        open={addDateModalOpen}
        onClose={() => setAddDateModalOpen(false)}
      >
        <ScheduleInspectDateModal
          propertyId={thisEnquiry.propertyId}
          close={closeModals}
        />
      </MiniModal>
      <MiniModal
        open={addTimeModalOpen}
        onClose={() => setAddTimeModalOpen(false)}
      >
        <AddTimeSlotModal dateId={currentDateId} close={closeModals}/>
      </MiniModal>
      <div className="flex">
        <div className="inner-wrapper">
          <div className="flex pt-3">
            <div className="back-icon" onClick={() => history.goBack()}>
              <i className="fas fa-chevron-left" />
            </div>
            <div className="listing-full">{thisEnquiry.property?.name}</div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="per-details">
                <label className="tab-label">User</label>
                <span className="tab-text">{`${thisEnquiry.user?.firstName} ${thisEnquiry.user?.lastName}`}</span>
              </div>
              <div className="per-details">
                <label className="tab-label">Status</label>
                <span className="tab-text">Enquires</span>
              </div>
              <div className="per-details">
                <label className="tab-label">State</label>
                <span className="tab-text">{thisEnquiry.property?.state}</span>
              </div>
              <div className="per-details">
                <label className="tab-label">Locality</label>
                <span className="tab-text">{thisEnquiry.property?.lga}</span>
              </div>
              <div className="per-details">
                <label className="tab-label">Area</label>
                <span className="tab-text">{thisEnquiry.property?.area}</span>
              </div>
              <div className="per-details">
                <label className="tab-label">Inspection</label>
                <span className="tab-text">4:00PM - 22/2/21</span>
              </div>
              <div className="per-details">
                <label className="tab-label">Date Applied</label>
                <span className="tab-text">4:00PM - 22/2/21</span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="flex-item">
                <div className="listing-actions">
                  <button className="btn-outline">View Property</button>
                  <button className="btn-outline">Upload Document</button>
                  <button className="btn-outline">View Application Form</button>
                </div>
                <div className="schedule-box">
                  <div className="flex-item mb-2">
                    <div className="sch-txt mr-5">Scheduled Dates</div>
                    <button
                      className="sm-outline"
                      onClick={() => setAddDateModalOpen(true)}
                    >
                      + &nbsp; Add Date
                    </button>
                  </div>
                  {inspectionDates && inspectionDates.length > 0
                    ? inspectionDates.map((date, index) => {
                        return (
                          <>
                            <div className="sch-container">
                              <div className="sch-txt bold">
                                <Moment format="MMM DD YYYY">{date.date}</Moment>
                              </div>
                              <div className="flex-item">
                                {date.times?.map((time, index) => {
                                  return (
                                    <div className="time-picker"><Moment format="hh:mm A">{time.time}</Moment></div>
                                  );
                                })}
                              </div>
                              <div className="flex-item">
                                <button className="sm-outline" onClick={() => openTimeSlotModal(date.id)}>
                                  Add Time Slots
                                </button>
                                {/* <i className="fas fa-trash-alt del" /> */}
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
