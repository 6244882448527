import React, { useState} from "react";
//@ts-ignore
// import Modal from "../../Utilities/Modal/Modal";
import Modal from "../../Utilities/Modal/MiniModal";
import AddPayment from "./AddPayment";

export default function Payments() {
	const [openPaymentModal, setOpenPaymentModal] = useState(false);
	
	const togglePaymentModal = () => {
		setOpenPaymentModal(!openPaymentModal);
	}
	
	
	return (
		<>
			<Modal open={openPaymentModal} onClose={() => setOpenPaymentModal(false)}>
				<AddPayment close={() => setOpenPaymentModal(false)} />
			</Modal>
		
			<div className="admin-inner">
				<div className="inner-wrapper">
					<div className="flex all-tabs">
						<div className="mini-tabs active">Fees &amp; Commision</div>
						<div className="mini-tabs">Configuration</div>
						<div className="mini-tabs">Lorem</div>
					</div>
					<div className="flex-item mb-4">
						<button className="primary-btn" onClick={() => togglePaymentModal()}>Add New</button>
					</div>
					<div className="w-50">
						<div className="table-box">
							<table className="table table-hover">
								<thead className="ag-thead">
									<tr>
										<th scope="col">Name</th>
										<th scope="col">Assingned To</th>
										<th scope="col">Event</th>
										<th scope="col">Status</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>VAT</td>
										<td>Buy/Sell</td>
										<td>Buy Propery</td>
										<td>Active</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="pm-space" />
						<div className="pagination">
							<div className="page-arrows">
								<i className="fal fa-angle-left" />
							</div>
							<div className="page-number">1</div>
							<div className="page-number nb">of 238</div>
							<div className="page-arrows">
								<i className="fal fa-angle-right" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
