import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { User } from "../../Classes/User";
import { MainContext } from "../../Contexts/MainContext";
import Spinner from "../../Utilities/Spinner";
import { LoginModel } from "../../Utilities/Types";

export default function LogIn() {
	const [errorMessage, setErrorMessage] = useState("");
	const initialValues: LoginModel = { email: "", password: "" };
	const { setUser } = useContext(MainContext);
	let history = useHistory();
	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
	let errorIcon = <i className="fal fa-exclamation-circle"></i>;

	const login = async (details: LoginModel) => {
		setLoading(true);
		let user = new User();
		let data = await user.Login(details);
		if (data?.status) {
			setUser(data?.data);
			localStorage.setItem("user", JSON.stringify(user._userView ?? {}));
			localStorage.setItem("token", user._userView?.token ?? "");
			history.push("/dashboard");
			// addToast(data.message, { appearance: "success", autoDismiss: true });
			return;
		}
		setErrorMessage(data?.message ?? "");
		setLoading(false);
	};
	return (
		<div className="login">
			<div className="login-box">
				<div className="login-text">Welcome Admin!</div>
				<Formik
					initialValues={initialValues}
					onSubmit={async (
						values: LoginModel,
						{ setSubmitting }: FormikHelpers<LoginModel>
					) => {
						await login(values);
						// console.log(values);
					}}
				>
					<Form className="login-form">
						<p className={errorMessage ? "error" : "d-none"}>{errorMessage}</p>
						<div className="input-box">
							<div className="input-label">Username</div>
							<Field
								id="email"
								name="email"
								// type="email"
								className="formfield"
								placeholder=""
								required
							/>
						</div>
						<div className="input-box">
							<div className="input-label">Password</div>
							<Field
								type="password"
								name="password"
								className="formfield"
								placeholder=""
								required
							/>
						</div>
						<button className="secondary-btn w-100" type="submit">
							{loading ? <Spinner /> : "Log In"}
						</button>
					</Form>
				</Formik>
			</div>
		</div>
	);
}
