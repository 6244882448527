import React, { useState } from "react";
import Moment from "react-moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PropertyView } from "../../Utilities/Types";
//@ts-ignore
import Naira from "react-naira";
import Pagination from "../../Utilities/Pagination";
import { ApplicationView } from "../../Utilities/Types/ApplicationTypes";
import { Applications } from "../../Classes/Applications";
import { useToasts } from "react-toast-notifications";

export default function RentReliefListing({
  reliefs,
  search,
  list,
  showBank = false,
}: {
  reliefs?: ApplicationView[];
  search?: any;
  list: any;
  showBank?: Boolean
}) {
  console.log({ reliefs });
  const { addToast } = useToasts();
  const history = useHistory();
  const approveApplications = async (id?: number) => {
    try {
      const data = await Applications.approveApplications(id);
      if (!data.status) {
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  // const [miniTab, setMiniTab] = useState("pending");
  // const setTab = (tabname: string) => {
  // 	setMiniTab(tabname);
  // };
  return (
    <div className="inner-wrapper">
      {/* <div className="flex all-tabs d-none">
				<div
					className={`mini-tabs ${miniTab == "pending" ? "active" : ""}`}
					onClick={() => setTab("pending")}
				>
					Pending
				</div>
				<div
					className={`mini-tabs ${miniTab == "ongoing" ? "active" : ""}`}
					onClick={() => setTab("ongoing")}
				>
					Ongoing
				</div>
				<div
					className={`mini-tabs ${miniTab == "resolved" ? "active" : ""}`}
					onClick={() => setTab("resolved")}
				>
					Resolved
				</div>
			</div> */}

		{ showBank && (
			<div className="d-flex w-100 my-2">
				<div className="d-flex align-items-center px-3 py-2 border-1 bank-info mr-2">
					<i className="fas fa-search" />
					<div className="ml-3">
						<h6 className="mb-0">Providus Bank</h6>
						<h3><Naira>136670342.78</Naira></h3>
					</div>
				</div>
				<div className="d-flex align-items-center px-3 py-2 border-1 bank-info ml-2">
					<div className="ml-2">
						<h6 className="mb-0">Total Loan Requests</h6>
						<h3><Naira>15500000</Naira></h3>
					</div>
					<button className="primary-btn ml-5"> Send all for Approval</button>	
				</div>
			</div>
		)}
		<div className="flex-item justify-content-between mb-4">
			<div className="d-flex justify-content-between w-100 my-2">
				<div className="admin-search-bar-2">
				<i className="fas fa-search" />
				<input
					type="text"
					className="property-search"
					placeholder="Search"
					onChange={(e) => search(e.target.value)}
				/>
				</div>
				<div className="d-flex justify-content-between">
					<button className="admin-action"> Export  <i className="fas fa-file-export ml-3"></i> </button>
					<div className="admin-action">
						<select>
							<option value="">Filter</option>
						</select>
					</div>
				</div>
			</div>
		</div>
      <div className="table-box">
        <table className="table table-hover">
          <thead className="ag-thead">
            <tr>
              {/* <th scope="col">Type</th> */}
              <th scope="col">User</th>
              <th scope="col">Amount</th>
              <th scope="col">Term</th>
              <th scope="col">Type</th>
              <th scope="col">Schedule</th>
              <th scope="col">Time</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* <div className="user-avatar">
								{user?.medias && user?.medias.length > 0 ? (
									<img src={user.medias[0].url} alt="" />
								) : (
									`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName
										?.charAt(0)
										.toUpperCase()}`
								)}
							</div> */}
            {reliefs &&
              reliefs.map((relief, index) => {
                return (
                  <tr key={index}>
                    <td scope="row">
                      <div className="user-nav">
                        <div className="user-pic mr-2">
                          {`${relief.user?.firstName
                            ?.charAt(0)
                            .toUpperCase()}${relief.user?.lastName
                            ?.charAt(0)
                            .toUpperCase()}`}
                        </div>
                        {`${relief.user?.firstName} ${relief.user?.lastName}`}
                      </div>
                    </td>
                    <td>
                      <Naira>{relief.reliefAmount}</Naira>
                    </td>
                    <td>{""}</td>
                    <td>{"Rent Relief"}</td>
                    <td>{""}</td>
                    <td>
                      <Moment format="MM/DD/yyyy HH:MM">
                        {relief.dateCreated}
                      </Moment>
                    </td>

                    <td>
                      <div className="flex">
                        <div className="del-icon">
                          <i className="fas fa-times"></i>
                        </div>
                        <div className="accpt-icon" onClick={() => approveApplications(relief?.id)}>
                          <i className="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {/* {properties?.map((listing: any, i: any) => {
							return (
								<tr key={i}>
									<Link to={`/listings/request/${listing.propertyType?.id}`}>
										<th scope="row">{listing.propertyType?.name}</th>
									</Link>
									<td className="user">{listing.user?.firstName}</td>
									<td>{listing.state}</td>
									<td>{listing.lga}</td>
									<td>{listing.area ? listing.area : "-"}</td>
									<td>
										<Naira>{listing.budget}</Naira>
									</td>

									<td
										className={`status ${
											listing.status && listing.status == "PENDING"
												? "p"
												: listing.status == "SUCCESSFUL"
												? "s"
												: "f"
										}`}
									></td>
								</tr>
							);
						})} */}
          </tbody>
        </table>
      </div>
      <div className="pm-space" />
      {/* <Pagination details={listings} list={list} /> */}
    </div>
  );
}
