import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { User } from "../../Classes/User";
import AdminSpinner from "../../Utilities/AdminSpinner";
import { Metrics } from "../../Utilities/Types";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import { title } from "process";

const data = {
  labels: ["Buy", "Sell", "Rent Out", "Rent", "Cleaning", "Verification"],
  datasets: [
    {
      label: "",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#7CFCBA",
        "#7CFCBA",
        "#7CFCBA",
        "#7CFCBA",
        "#7CFCBA",
        "#7CFCBA",
      ],
      borderWidth: 1,
    },
  ],
};

const doughnutChart = {
  labels: ["Android", "Web Desktop", "IOS", "Web Mobile"],
  datasets: [
    {
      label: "",
      data: [12, 19, 3, 5],
      backgroundColor: ["#0042FF", "#F2351C", "#2FDF84", "#7365FF"],
      borderWidth: 1,
    },
  ],
};

const options = {
  indexAxis: "y",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState<Metrics>();
  const { addToast } = useToasts();

  const getUserCount = async () => {
    setLoading(true);
    const data = await User.userCount();
    if (!data?.status) {
      addToast(data.message, { appearance: "error", autoDismiss: true });
      setLoading(false);
      return;
    }
    setUserCount(data.data);
    setLoading(false);
    return;
  };
  useEffect(() => {
    const getDashboardData = async () => {
      await getUserCount();
    };
    getDashboardData();
  }, []);
  return loading ? (
    <AdminSpinner />
  ) : (
    <div className="admin-inner">
      <div className="admin-container">
        <div className="inner-content">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">All users</div>
                {/* <div className={`pm-card-body ${loading ? "sk" : ""}`}> */}
                <div className="pm-card-body">
                  <span>{userCount?.users}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">New users</div>
                <div className="pm-card-body">
                  <span>{userCount?.newUsers}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">Active users</div>
                <div className="pm-card-body">
                  <span>{userCount?.activeUsers}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">Lorem</div>
                <div className="pm-card-body">
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">Transction Value</div>
                <div className="pm-card-body">
                  <div className="pm-chart-box">
                    <Bar
                      data={data}
                      width={300}
                      height={100}
                      //@ts-ignore
                      options={{
                        maintainAspectRatio: false,
                        indexAxis: "y",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">Transction Value</div>
                <div className="pm-card-body">
                  <div className="pm-chart-box">
                    <Bar
                      data={data}
                      width={300}
                      height={100}
                      options={{
                        maintainAspectRatio: false,
                        indexAxis: "y",
                        bar: { datasets: { label: "thing" } },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">Transction Value</div>
                <div className="pm-card-body">
                  <div className="pm-chart-box">
                    <Line
                      data={data}
                      width={300}
                      height={100}
                      options={{
                        maintainAspectRatio: false,
                        indexAxis: "x",
                        bar: { datasets: { label: "thing" } },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">Transction Value</div>
                <div className="pm-card-body">
                  <div className="pm-chart-box">
                    <Doughnut
                      data={doughnutChart}
                      width={250}
                      height={100}
                      options={{
                        maintainAspectRatio: false,
                        indexAxis: "y",
                        bar: { datasets: { label: "thing" } },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-4 d-none">
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">
                  Top Users
                  <div className="pm-select">
                    <select>
                      <option value="">Active Services</option>
                    </select>
                  </div>
                </div>
                <div className="pm-card-body">
                  <div className="pm-user-box">
                    <ol>
                      <li>
                        <div className="user-pic">PO</div>
                        <div className="user-info">
                          <h4>Pade Omotoso</h4>
                          <span>5</span>
                        </div>
                      </li>
                      <li>
                        <div className="user-pic">PO</div>
                        <div className="user-info">
                          <h4>Pade Omotoso</h4>
                          <span>5</span>
                        </div>
                      </li>
                      <li>
                        <div className="user-pic">PO</div>
                        <div className="user-info">
                          <h4>Pade Omotoso</h4>
                          <span>5</span>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pm-card">
                <div className="pm-card-head">
                  Services
                  <div className="pm-select">
                    <select>
                      <option value="">Volume</option>
                    </select>
                  </div>
                </div>
                <div className="pm-card-body">
                  <div className="pm-user-box">
                    <ol>
                      <li>
                        <div className="user-info">
                          <h4>Buy</h4>
                          <span>5</span>
                        </div>
                      </li>
                      <li>
                        <div className="user-info">
                          <h4>Sell</h4>
                          <span>5</span>
                        </div>
                      </li>
                      <li>
                        <div className="user-info">
                          <h4>Rent</h4>
                          <span>5</span>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
