import React, { useState } from "react";
import Tabs from "./ListingTabs/tabs";
//@ts-ignore
import Modal from "../../Utilities/Modal/Modal";
import AddRentListing from "./RentModals/AddRentListing";

export default function ForRentTab() {
	const [miniTab, setMiniTab ] = useState('help-list');
	const [newListingModal, setNewListingModal] = useState(false)
	
	const setTab = (name: string) => {
		setMiniTab(name)
	}
	
	const toggleNewListingModal = () => {
		setNewListingModal(!newListingModal);
	}
	
	
	return (
		<>
			<Modal open={newListingModal} onClose={() => toggleNewListingModal}>
				<AddRentListing close={() => toggleNewListingModal()} />
			</Modal>
		
			<div className="inner-wrapper">
				<div className="flex justify-content-between align-items-center">
					<div className="flex all-tabs">
						<div className={`mini-tabs ${miniTab == "help-list" ? 'active' : ''}`} onClick={() => setTab("help-list")}>Help To List</div>
						<div className={`mini-tabs ${miniTab == "active-list" ? 'active' : ''}`} onClick={() => setTab("active-list")}>Active Listings</div>
						<div className={`mini-tabs ${miniTab == "closed-list" ? 'active' : ''}`} onClick={() => setTab("closed-list")}>Closed Listings</div>
					</div>
					{
						miniTab == "active-list" && (
							<div>
								<button className="primary-btn" onClick={() => toggleNewListingModal()}>Add New Listing</button>
							</div>
						)
					}
				</div>
				<div className="flex-item mb-4">
					<div className="admin-search-bar-2">
						<i className="fas fa-search" />
						<input type="text" className="property-search" placeholder="Search" />
					</div>
				</div>
				{ miniTab == "help-list" ? (
						<Tabs isHelp={true} />
					) : miniTab == "active-list" ? (
						<Tabs isActive={true} />
					) : (
						<Tabs isClosed={true} />
					)
				}
				<div className="pm-space" />
				<div className="pagination">
					<div className="page-arrows">
						<i className="fal fa-angle-left" />
					</div>
					<div className="page-number">1</div>
					<div className="page-number nb">of 238</div>
					<div className="page-arrows">
						<i className="fal fa-angle-right" />
					</div>
				</div>
			</div>
		</>
	);
}
