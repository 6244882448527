import React from "react";
import { useHistory } from "react-router";
import Moment from "react-moment";
import { useLocation } from "react-router";
import { CleanView } from "../../Utilities/Types";

export default function CleaningDetails() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state as any;
  const clean = state?.clean as CleanView;

  return (
    <div className="admin-inner">
      <div className="flex">
        <div className="pm-tab active">
          <div className="flex">
            <i className="fas fa-bell" />
            <span>Sessions</span>
          </div>
          <div className="pm-tab-count">2</div>
        </div>
        <div className="pm-tab">
          <div className="flex">
            <i className="far fa-users" />
            <span>Cleaners</span>
          </div>
          <div className="pm-tab-count">2</div>
        </div>
      </div>

      <div className="inner-wrapper">
        <div className="flex pt-3">
          <div className="back-icon" onClick={() => history.push("/cleanings")}>
            <i className="fas fa-chevron-left" />
          </div>
          <div className="listing-full">{clean.propertyType}</div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="per-details">
              <label className="tab-label">User</label>
              <span className="tab-text">{clean.user?.fullName}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Mobile</label>
              <span className="tab-text">{clean.user?.phoneNumber}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Email</label>
              <span className="tab-text">{clean.user?.email}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Purpose</label>
              <span className="tab-text">{clean.propertyType}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">State</label>
              <span className="tab-text">{clean.buildingState}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Floors</label>
              <span className="tab-text">{clean.numberOfFloors}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Location</label>
              <span className="tab-text">Lagos</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Preferred Date</label>
              <span className="tab-text">
                <Moment format="DD/MM/YY">{clean.dateNeeded}</Moment>
              </span>
            </div>
          </div>
          {/* Pick Appropriate component for usage  */}
          <div className="col-lg-4">
            <div className="s-items">
              <div className="user-name-a mb-4">Quotes</div>
              <div className="input-box">
                <input type="text" className="formfield reup" placeholder="" />
                <i className="far fa-trash-alt prf" />
              </div>
            </div>
            <div className="s-items">
              <div className="user-name-a mb-4">Send Another Quote</div>
              <div className="input-box">
                <div className="input-label">Enter Amount</div>
                <input type="text" className="formfield" placeholder="" />
              </div>
            </div>
            <button className="secondary-btn w-100">Send Quote</button>
          </div>
          <div className="col-lg-4">
            <div className="user-name-a mb-2">Preferred Date</div>
            <div className="input-box">
              <input type="date" className="i-date" placeholder="2021 08 21" />
              <i className="far fa-edit prf" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
