import React from "react";
import { PulseSpinner } from "react-spinners-kit";

export default function AdminSpinner(props: any) {
	const { color, size } = props;
	return (
		<div className="spin">
			<PulseSpinner
				color={!color ? "#0042ff" : color == "white" ? "#fff" : color}
				size={!size ? "60" : size}
				loading={true}
			/>
		</div>
	);
}
