import React from "react";

export default function AddRentListing({ close } : { close: any}) {
	return (
		<div>
			<div className="flex-item">
				<div className="item-page">Add a New Listing</div>
				<i className="fal fa-times cancel" onClick={close} />
			</div>
			<div className="row mt-4 px-3">
				<div className="col-lg-6">
					<div className="property-info-box">
						<div className="input-box">
							<div className="input-label">Property Name</div>
							<input type="text" className="formfield" placeholder="" />
						</div>
						<div className="input-box">
							<div className="input-label">Property Type</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>						
						<div className="input-box">
							<div className="input-label">No. of Bedrooms</div>
							<input type="text" className="formfield" placeholder="" />
						</div>
						<div className="input-box">
							<div className="input-label">No. of Toilets</div>
							<input type="text" className="formfield" placeholder="" />
						</div>
						<div className="input-box">
							<div className="input-label">Rent Per Annum</div>
							<input type="text" className="formfield" placeholder="" />
						</div>
						<div className="input-box">
							<div className="input-label">State</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
						<div className="input-box">
							<div className="input-label">Locality</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
						<div className="input-box">
							<div className="input-label">Area</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
						<div className="input-box">
							<div className="input-label">Address</div>
							<input type="text" className="formfield" placeholder="" />
						</div>
						<div className="input-box">
							<div className="input-label">Type of Tenant</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="property-info-box">
						<div className="input-box">
							<div className="input-label">Annual Income Bracket</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
						<div className="input-box">
							<div className="input-label">Rent Collection Frequency</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
						<div className="input-box">
							<div className="input-label">Owner's Bank</div>
							<div className="select-box">
								<select className="formfield">
									<option>Choose an option</option>
								</select>
								<div className="arrows" />
							</div>
						</div>
						<div className="input-box">
							<div className="input-label">Owner's Account Number</div>
							<input type="text" className="formfield" />
						</div>
						<div className="input-box">
							<div className="input-label">Details</div>
							<textarea className="input-txt" defaultValue={""} />
							<div className="notes">Use | to separate individual detail</div>
						</div>
						<div className="single-form">
							<label className="admin-form">Add Images</label>
							<div className="flex img-container">
								<div className="admin-img-field">
									<input type="file" className="adds" />
								</div>
								<div className="admin-img-field selected">
									<input type="file" hidden />
									<img
										src="/assets/1200px-Bispingen_verrücktes_Haus_auf_dem_Kopf.jpg"
										alt=""
									/>
								</div>
								<div className="admin-img-field">
									<input type="file" hidden />
									<img
										src="/assets/1200px-Bispingen_verrücktes_Haus_auf_dem_Kopf.jpg"
										alt=""
									/>
								</div>
								<div className="admin-img-field">
									<input type="file" hidden />
									<img
										src="/assets/1200px-Bispingen_verrücktes_Haus_auf_dem_Kopf.jpg"
										alt=""
									/>
								</div>
								<div className="admin-img-field">
									<input type="file" hidden />
									<img
										src="/assets/1200px-Bispingen_verrücktes_Haus_auf_dem_Kopf.jpg"
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="single-form">
							<label className="admin-form">Add Interactive 3D tour</label>
							<div className="flex img-container">
								<div className="admin-img-field">
									<input type="file" className="adds" />
								</div>
							</div>
						</div>
						<button className="secondary-btn w-100">Add Listing</button>
					</div>
				</div>
			</div>
		</div>
	);
}
