import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { AllListings } from "../../Classes/AllListings";
import { Applications } from "../../Classes/Applications";
import AdminSpinner from "../../Utilities/AdminSpinner";
import { PagedApiResponseData } from "../../Utilities/Types";
import { ApplicationView } from "../../Utilities/Types/ApplicationTypes";
import RentReliefListing from "./RentReliefListing";

export default function RentReliefBody() {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState<any>([]);
  const [reliefs, setReliefs] = useState<ApplicationView[]>();
  const [reliefResponse, setReliefResponse] = useState<PagedApiResponseData>();
  const [acceptedReliefs, setAcceptedReliefs] = useState<ApplicationView[]>();
  const [acceptedReliefsResponse, setAcceptedReliefsResponse] = useState<PagedApiResponseData>();
  const [reviewedReliefs, setReviewedReliefs] = useState<ApplicationView[]>();
  const [reviewedReliefsResponse, setReviewedReliefsResponse] = useState<PagedApiResponseData>();
  const [approvedReliefs,setApprovedReliefs] = useState<ApplicationView[]>();
  const [approvedReliefsResponse, setApprovedReliefsResponse] = useState<PagedApiResponseData>();
  const [request, setRequest] = useState<any>([]);
  const [enquires, setEnquires] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("requests");

  const setCurrentTab = (tabname: string) => {
    setTab(tabname);
  };

  const handleRentSearch = () => {
    const url = `?search=${search}`;
    listProperties(url);
  };

  const listReliefApplications = async (url?: string) => {
    if (!url) {
      setLoading(true);
    }
    try {
      const data = await Applications.listReliefApplications(url);
      if (!data.status) {
        setLoading(false);
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      setReliefs(data.data.value);
      setReliefResponse(data.data);
      setLoading(false);
    } catch (error) {}
  };

  const listAcceptedReliefApplications = async (url?: string) => {
    if (!url) {
      setLoading(true);
    }
    try {
      const data = await Applications.listAcceptedReliefApplications(url);
      if (!data.status) {
        setLoading(false);
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      setAcceptedReliefs(data.data.value);
      setAcceptedReliefsResponse(data.data);
      setLoading(false);
    } catch (error) {}
  };

  const listReviewedReliefApplications = async (url?: string) => {
    if (!url) {
      setLoading(true);
    }
    try {
      const data = await Applications.listReviewedReliefApplications(url);
      if (!data.status) {
        setLoading(false);
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      setReviewedReliefs(data.data.value);
      setReviewedReliefsResponse(data.data);
      setLoading(false);
    } catch (error) {}
  };

  const listApprovedReliefApplications = async (url?: string) => {
    if (!url) {
      setLoading(true);
    }
    try {
      const data = await Applications.listApprovedReliefApplications(url);
      if (!data.status) {
        setLoading(false);
        addToast(data.message, { appearance: "error", autoDismiss: true });
        return;
      }
      setApprovedReliefs(data.data.value);
      setApprovedReliefsResponse(data.data);
      setLoading(false);
    } catch (error) {}
  };

  const listProperties = async (url?: any) => {
    console.log({ url });

    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allListings(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setListings(data.data);

    setLoading(false);
    return;
  };

  const listRequests = async (url?: any) => {
    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allRequest(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setRequest(data.data);
    setLoading(false);
    return;
  };
  const listEnquires = async (url?: any) => {
    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allEnquires();
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setEnquires(data.data);
    setLoading(false);
    return;
  };
  const updateSearchString = async (char: any) => {
    setSearch(char);
    await handleRentSearch();
  };

  useEffect(() => {
    const fetchData = async () => {
      // await listProperties();
      // await listRequests();
      // await listEnquires();
	  await listReliefApplications();
    await  listAcceptedReliefApplications();
    await listReviewedReliefApplications();
    await listApprovedReliefApplications();
    };

    fetchData();
  }, []);

  return loading ? (
    <AdminSpinner />
  ) : (
    <div className="admin-inner">
      <div className="flex rent-relief">
        <div
          className={`pm-tab ${tab == "requests" ? "active" : ""}`}
          onClick={() => setCurrentTab("requests")}
        >
          <div className="flex align-items-center">
            <i className="fas fa-arrow-down" />
            <span>Requests</span>
          </div>
          <div className="pm-tab-count">{reliefResponse?.size}</div>
        </div>
        <div
          className={`pm-tab ${tab == "review" ? "active" : ""}`}
          onClick={() => setCurrentTab("review")}
        >
          <div className="flex align-items-center">
            <i className="far fa-fingerprint" />
            <span>Review</span>
          </div>
          <div className="pm-tab-count">2</div>
        </div>
        <div
          className={`pm-tab ${tab == "sourcing" ? "active" : ""}`}
          onClick={() => setCurrentTab("sourcing")}
        >
          <div className="flex align-items-center">
            <i className="far fa-file-search" />
            <span>Sourcing</span>
          </div>
          <div className="pm-tab-count">2</div>
        </div>
        <div
          className={`pm-tab ${tab == "approval" ? "active" : ""}`}
          onClick={() => setCurrentTab("approval")}
        >
          <div className="flex align-items-center">
            <i className="far fa-user-check" />
            <span>Approval</span>
          </div>
          <div className="pm-tab-count">2</div>
        </div>
      </div>

      {tab == "requests" ? (
        <RentReliefListing
          reliefs={reliefs}
          list={listProperties}
          search={updateSearchString}
        />
      ) : tab == "review" ? (
        <RentReliefListing
          reliefs={reliefs}
          list={listProperties}
          search={updateSearchString}
        />
      ) : tab == "sourcing" ? (
        <RentReliefListing
          reliefs={reliefs}
          list={listProperties}
          search={updateSearchString}
          showBank={true}
        />
      ) : (
        <RentReliefListing
          reliefs={reliefs}
          list={listProperties}
          search={updateSearchString}
          showBank={true}
        />
      )}
    </div>
  );
}
