import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { AllListings } from "../../Classes/AllListings";
import { UserEnquiry } from "../../Types/api";
import AdminSpinner from "../../Utilities/AdminSpinner";
import Fetch from "../../Utilities/Fetch";
import {
  PagedApiResponseData,
  PropertyView,
  UserEnquiries,
} from "../../Utilities/Types";
import Enquires from "./Enquires";
import Listings from "./Listings";
import RequestListings from "./RequestListings";

export default function ListingBody() {
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState<any>([]);
  const [request, setRequest] = useState<any>([]);
  const [enquires, setEnquires] = useState<UserEnquiry[]>([]);
  const [propertiesResponse, setPropertiesResponse] =
    useState<PagedApiResponseData>();
  const [requestResponse, setRequestResponse] =
    useState<PagedApiResponseData>();
  const [enquiresResponse, setEnquiresResponse] =
    useState<PagedApiResponseData>();

  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("listings");
  const { addToast } = useToasts();

  const setCurrentTab = (tabname: string) => {
    setTab(tabname);
  };

  const searchProperties = async () => {
    const url = `?search=${search}`;
    listProperties(url);
  };
  const listProperties = async (url?: any) => {
    console.log({ url });

    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allListings(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setProperties(data.data);

    setLoading(false);
    return;
  };
  const listRequests = async (url?: any) => {
    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allRequest(url);
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setRequest(data.data);
    setLoading(false);
    return;
  };

  const listEnquires = async (url?: any) => {
    if (!url) {
      setLoading(true);
    }
    let data = await AllListings.allEnquires();
    if (!data?.status) {
      setLoading(false);
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setEnquires(data.data.value);
    setEnquiresResponse(data.data);
    setLoading(false);
    return;
  };

  const updateSearchString = async (char: any) => {
    setSearch(char);
    await searchProperties();
  };
  useEffect(() => {
    const displayListings = async () => {
      await listProperties();
      await listRequests();
      await listEnquires();
    };
    displayListings();
  }, []);
  return loading ? (
    <AdminSpinner />
  ) : (
    <div className="admin-inner">
      <div className="flex">
        <div
          className={`pm-tab ${tab == "enquires" ? "active" : ""}`}
          onClick={() => setCurrentTab("enquires")}
        >
          <div className="flex align-items-center">
            <i className="fas fa-arrow-down" />
            <span>Enquires</span>
          </div>
          <div className="pm-tab-count">{enquiresResponse?.size}</div>
        </div>
        <div
          className={`pm-tab ${tab == "requests" ? "active" : ""}`}
          onClick={() => setCurrentTab("requests")}
        >
          <div className="flex align-items-center">
            <i className="far fa-bell" />
            <span>Requests</span>
          </div>
          <div className="pm-tab-count">{requestResponse?.size}</div>
        </div>
        <div
          className={`pm-tab ${tab == "listings" ? "active" : ""}`}
          onClick={() => setCurrentTab("listings")}
        >
          <div className="flex align-items-center">
            <i className="far fa-building" />
            <span>Listings</span>
          </div>
          <div className="pm-tab-count">{propertiesResponse?.size}</div>
        </div>
      </div>
      {tab == "listings" ? (
        <Listings
          listings={properties}
          search={updateSearchString}
          list={listProperties}
        />
      ) : tab == "requests" ? (
        <RequestListings
          listings={request}
          search={updateSearchString}
          list={listRequests}
        />
      ) : (
        <Enquires
          enquiries={enquires}
          search={updateSearchString}
          list={listEnquires}
        />
      )}
    </div>
  );
}
