import React from "react";
import { UserView } from "../../Utilities/Types";

export default function UserProfile({ user }: { user?: UserView }) {
	return (
		<div className="profile-tab-content">
			<div className="row">
				<div className="col-lg-4">
					<div className="per-details">
						<label className="tab-label">First Name</label>
						<span className="tab-text">{user?.firstName}</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Surname</label>
						<span className="tab-text">{user?.lastName}</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Email</label>
						<span className="tab-text">{user?.email}</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Mobile</label>
						<span className="tab-text">{user?.phoneNumber}</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Address</label>
						<span className="tab-text">{user?.lastName}</span>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="per-details">
						<label className="tab-label">All-time RentRelief</label>
						<span className="tab-text">N2,500.00</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Current RentRelief</label>
						<span className="tab-text">N0.00</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Total Listings</label>
						<span className="tab-text">0</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Total Sales</label>
						<span className="tab-text">0</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Total Purchase</label>
						<span className="tab-text">0</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Active Tenancy</label>
						<span className="tab-text">No</span>
					</div>
					<div className="per-details">
						<label className="tab-label">Total Sessions</label>
						<span className="tab-text">0</span>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="switch">
						<span className="checkbox-text">Account Active</span>
						<label className="ag-toggle">
							<input type="checkbox" defaultChecked />
							<span className="slider" />
						</label>
					</div>
				</div>
			</div>
		</div>
	);
}
