import {
  Endpoints,
  Statuses,
  HttpMethods,
  ErrorMessages,
} from "../Utilities/Enums";
import Fetch from "../Utilities/Fetch";

import {
  ApiResponse,
  PagedApiResponse,
  PropertyInput,
  PropertyView,
  Response,
  UserView,
} from "../Utilities/Types";

export class Tenancy {
  public static allTenancies = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.ALL_TENANCIES}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
}
