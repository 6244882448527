import React from "react";
import { UserView } from "../../Utilities/Types";

export default function UserList({
	users,
	totalPages,
	setUser,
	paginate,
	currentPage,
	search,
}: {
	users: UserView[];
	totalPages?: number;
	setUser: any;
	paginate?: any;
	currentPage?: number;
	search?: any;
}) {
	return (
		<div className="list-users">
			<div className="list-user-wrapper">
				<div className="admin-search-bar">
					<i className="far fa-search" />
					<input
						type="text"
						className="admin-search"
						placeholder="Search"
						onChange={(e) => search(e.target.value)}
					/>
				</div>
				{users.map((user, i) => {
					return (
						<div className="user-box" onClick={() => setUser(user.id)}>
							{user.fullName}
						</div>
					);
				})}
			</div>
			<div className="pagination">
				<div
					className="page-arrows"
					onClick={() => {
						paginate("previous");
					}}
				>
					<i className="fal fa-angle-left" />
				</div>
				<div className="page-number">{currentPage}</div>
				<div className="page-number nb">{`of ${
					totalPages === 0 ? "1" : totalPages
				}`}</div>
				<div
					className="page-arrows"
					onClick={() => {
						paginate("next");
					}}
				>
					<i className="fal fa-angle-right" />
				</div>
			</div>
		</div>
	);
}
