import React from "react";

export default function Complaints({close}: {close: Function}) {
	return (
		<div>
			<div className="flex-item">
				<div className="item-page">Complaints</div>
				<i className="fal fa-times cancel" onClick={() => close()} />
			</div>
			<div className="flex align-items-start px-3 mt-5">
				<div className="complaint-list">
					<ul>
						<li className="active">
							<h6>Structural Damage</h6>
							<span>10/04/21</span>
						</li>
						<li>
							<h6>Structural Damage</h6>
							<span>10/04/21</span>
						</li>
						<li>
							<h6>Structural Damage</h6>
							<span>10/04/21</span>
						</li>
					</ul>
				</div>
				<div className="single-complaint-info">
					<div className="flex-item align-items-start">
						<ul>
							<li className="active">
								<span>Category</span>
								<h6>Structural Damage</h6>
							</li>
							<li>
								<span>Sub Category</span>
								<h6>Structural Damage</h6>
							</li>
							<li>
								<span>Comment</span>
								<h6>Structural Damage</h6>
							</li>
						</ul>
						<i className="far fa-trash-alt" />
					</div>
				</div>
			</div>
		</div>
	);
}
