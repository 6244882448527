import React, { useState } from "react";
// @ts-ignore
import MiniModal from "../../Utilities/Modal/MiniModal";
import Complaints from "./RentModals/Complaints";
import SingleListing from "../Listings/SingleListing";

export default function ApplicationDetails() {
	const [openProperty, setOpenProperty] = useState(false);
	
	const openPropertyModal = () => {
		setOpenProperty(!openProperty);
	}
	
	return (
		<>
			<MiniModal open={openProperty} onClose={() => setOpenProperty(false)}>
				<SingleListing isModal={true} />
			</MiniModal>
	  
			<div className="flex top my-3 mx-3">
				<div className="screen-half application mr-1">
					<div className="single-rent-top mb-0">
						<div className="sale-type ten">Active Tenancy</div>
						<div className="flex pt-3">
							<div className="back-icon">
								<i className="fas fa-chevron-left" />
							</div>
							<div className="listing-full">4 Bedroom Terrace with Pool</div>
						</div>
					</div>
                    <div className="container">
                        <div className="row bg-white">
                            <div className="col-12 py-2 px-4">
                                <div className="list-tip my-2">Applicant's Details</div>                                
                            </div>
                        </div>
                        <div className="row bg-white">
                            <div className="col-6">
                                <div className="application-col">
                                    <div className="per-details">
                                        <label className="tab-label">Passport Photograph</label>
                                        <div className="pic-box mt-3" />
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">First Name</label>
                                        <span className="tab-text">Pade</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Middle Name</label>
                                        <span className="tab-text">Craig</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Surame</label>
                                        <span className="tab-text">Omotosho</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Mobile Number</label>
                                        <span className="tab-text">080 1234 5678</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Email</label>
                                        <span className="tab-text">padeomotoso@gmail.com</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Current Residential Address</label>
                                        <span className="tab-text">20 Adedoyin Oludaree Street Omole Phase 4, Ikeja, Lagos</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Date of Birth</label>
                                        <span className="tab-text">13/04/80</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Nationality</label>
                                        <span className="tab-text">Nigerian</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Marital Status</label>
                                        <span className="tab-text">Married</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Occupation</label>
                                        <span className="tab-text">Lawyer</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Employer</label>
                                        <span className="tab-text">Banwo &amp; Ighodalo</span>
                                    </div>
                                    
                                </div>
                            </div>
                            
                            <div className="col-6">
                                <div className="application-col">
                                    <div className="per-details">
                                        <label className="tab-label">Work ID</label>
                                        <div className="pic-box mt-3" />
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Work Address</label>
                                        <span className="tab-text">33D Cameron Road, Ikoyi, Lagos</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Annual Income</label>
                                        <span className="tab-text">₦13,670,000</span>
                                    </div>
                                    
                                    <div className="list-tip my-3">Next of Kin</div>
                                    
                                    <div className="per-details">
                                        <label className="tab-label">First Name</label>
                                        <span className="tab-text">Bunmi</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Surname</label>
                                        <span className="tab-text">Omotosho</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Mobile Number</label>
                                        <span className="tab-text">09876543211</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Email</label>
                                        <span className="tab-text">bunmitosh@yahoo.com</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Address</label>
                                        <span className="tab-text">20 Adedoyin Oludaree Street Omole Phase 4, Ikeja, Lagos</span>
                                    </div>
                                    <div className="per-details">
                                        <label className="tab-label">Location</label>
                                        <span className="tab-text">Omole Phase 1 , Lagos</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
				
				<div className="screen-third                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
					<div className="flex justify-content-end bg-white p-3">
                        <button className="btn-outline" onClick={() => openPropertyModal()}>View Property</button>
					</div>
					<div className="single-rent-btm">
						<div className="list-tip my-2">Owner's Details</div>
						<div className="per-details">
							<label className="tab-label">Name</label>
							<span className="tab-text">Kamoru Hopewell</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Email</label>
							<span className="tab-text">kamoruhopewell@gmail.com</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Mobile</label>
							<span className="tab-text">09876543211</span>
						</div>
                        <div className="list-tip my-3">Owner's Requirements</div>
						<div className="per-details">
							<label className="tab-label">Management</label>
							<span className="tab-text">Self</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Tenant Type</label>
							<span className="tab-text">Family/Individual</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Income Bracket</label>
							<span className="tab-text">₦500,000 - ₦750,000</span>
						</div>
						<div className="per-details">
							<label className="tab-label">Frequency of Rent Collection</label>
							<span className="tab-text">Annually</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
