import { HttpMethods } from './../Utilities/Enums';
import { InspectionDateModel } from './../Types/api/inspection-date-model';
import { Endpoints, ErrorMessages, Statuses } from "../Utilities/Enums";
import Fetch from "../Utilities/Fetch";
import { ApiResponse, PagedApiResponse, Response } from "../Utilities/Types";
import { InspectionTimeModel } from '../Types/api';

export class Properties {
  public static listInspectionDates = async (id?: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.LIST_INSPECTION_DATES}${id}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static addInspectionDates = async (date:InspectionDateModel) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.ADD_INSPECTION_DATES}`,HttpMethods.POST,date
      )) as unknown as ApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };


  public static addInspectionTime = async (date:InspectionTimeModel) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.ADD_INSPECTION_TIME}`,HttpMethods.POST,date
      )) as unknown as ApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
}


