import { Endpoints, ErrorMessages, Statuses } from "../Utilities/Enums";
import Fetch from "../Utilities/Fetch";
import { PagedApiResponse, Response } from "../Utilities/Types";

export class Applications {
  public static listReliefApplications = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.LIST_PENDING_RELIEF_APPLICATION}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static listReviewedReliefApplications = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.LIST_REVIEWED_RELIEF_APPLICATION}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static listAcceptedReliefApplications = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.LIST_ACCEPTED_RELIEF_APPLICATION}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static listApprovedReliefApplications = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.LIST_APPROVED_RELIEF_APPLICATION}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static listRentApplications = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.LIST_RENT_APPLICATIONS}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static approveApplications = async (id?: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.APPROVE_APPLICATION}${id}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static acceptApplications = async (id?: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.ACCEPT_APPLICATION}${id}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static reviewApplications = async (id?: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.REVIEW_APPLICATION}${id}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static rejectApplications = async (id?: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Admin.REJECT_APPLICATION}${id}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
}
