import MaterialModal from "@material-ui/core/Modal";
import React from "react";
import { MiniWrapper } from "./ModalWrapper";

function Modal(props: any) {
	return (
		<MaterialModal
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="This is a modal"
			aria-describedby="A modal description"
			disableBackdropClick={false}
		>
			<MiniWrapper style={{ height: props.height, width: props.width }}>
				{props.children}
			</MiniWrapper>
		</MaterialModal>
	);
}

export default Modal;
