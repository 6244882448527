import React from "react";

export default function AddPayment({ close }: { close: any }) {
	return (
		<div>
			<div className="flex-item">
				<div className="item-page">Add Fees</div>
				<i className="fal fa-times cancel" onClick={() => close} />
			</div>
			<form className="mt-5">
				<div className="input-box">
					<div className="input-label">Name</div>
					<input type="text" className="formfield" placeholder="" />
				</div>
				<div className="input-box">
					<div className="input-label">Amount</div>
					<input type="text" className="formfield" placeholder="" />
				</div>
				<div className="input-box">
					<div className="input-label">Service</div>
					<div className="select-box">
						<select className="formfield">
							<option>Choose an option</option>
						</select>
						<div className="arrow" />
					</div>
				</div>
				<div className="input-box">
					<div className="input-label">Transaction Trigger</div>
					<div className="select-box">
						<select className="formfield">
							<option>Choose an option</option>
						</select>
						<div className="arrow" />
					</div>
				</div>
				<div className="switch mx-auto w-fit">
					<span className="checkbox-text">Enable</span>
					<label className="ag-toggle">
						<input type="checkbox" />
						<span className="slider" />
					</label>
				</div>
				<button className="secondary-btn w-100">Add</button>
			</form>
		</div>
	);
}
