import React, { useEffect, useState } from "react";
import { User } from "../../Classes/User";
import AdminSpinner from "../../Utilities/AdminSpinner";
import { PropertyView, UserView } from "../../Utilities/Types";
import UserList from "./UserList";
import UserListings from "./UserListings";
import UserLoaded from "./UserLoaded";
import UserProfile from "./UserProfile";

export default function UserBody() {
	const [loading, setLoading] = useState(false);
	const [error, setErrorMessage] = useState("");
	const [tabName, setTabName] = useState("profile");
	const [pagingDetails, setPagingDetails] = useState<any>();
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const [totalPages, setTotalPages] = useState(0);
	const [selectedUser, setSelectedUser] = useState<UserView>();
	const [users, setUsers] = useState<UserView[]>();

	const listUsers = async (url?: string) => {
		if (!url) {
			setLoading(true);
		}
		let data = await User.listUsers(url);
		if (!data.status) {
			setErrorMessage(data.message);
			setLoading(false);
			return;
		}
		setTotalPages(Math.floor(data.data.size / data.data.limit));
		setCurrentPage((data.data.limit + data.data.offset) / data.data.limit);
		setUsers(data.data.value);
		setPagingDetails(data.data);
		setSelectedUser(data.data.value[0]);
		console.log(data);

		setLoading(false);
		return;
	};
	const searchUsers = async () => {
		const url = `?search=${search}`;
		listUsers(url);
	};

	const paginate = async (direction: any) => {
		console.log({ pagingDetails });

		let url = "";
		if (direction == "next") {
			url = pagingDetails.next.href.split("list")[1];
			await listUsers(url);
			return;
		}
		url = pagingDetails.previous.href.split("list")[1];
		await listUsers(url);
		return;
	};

	const setUser = (id: any) => {
		let selectedUser = users?.filter((record) => record.id == id)[0];
		setSelectedUser(selectedUser);
	};

	const updateSearchString = async (char: any) => {
		setSearch(char);
		await searchUsers();
	};

	useEffect(() => {
		const fetchInitialData = async () => {
			await listUsers();
		};
		fetchInitialData();
	}, []);

	return loading ? (
		<AdminSpinner />
	) : (
		<div className="admin-inner">
			<div className={error ? "error" : "d-none"}>{error}</div>
			<div className="flex align-items-start divided">
				<UserList
					users={users && users.length > 0 ? users : []}
					totalPages={totalPages}
					setUser={setUser}
					paginate={paginate}
					currentPage={currentPage}
					search={updateSearchString}
				/>
				<div className="user-content-box">
					<UserLoaded users={selectedUser} listUsers={listUsers} />
					<div className="admin-tab">
						<div
							className={`single-admin-tab ${
								tabName == "profile" ? "current" : ""
							}`}
							onClick={() => setTabName("profile")}
						>
							Profile
						</div>
						<div
							className={`single-admin-tab ${
								tabName == "listings" ? "current" : ""
							}`}
							onClick={() => setTabName("listings")}
						>
							Listings
						</div>
						<div
							className={`single-admin-tab ${
								tabName == "settings" ? "current" : ""
							}`}
							onClick={() => setTabName("settings")}
						>
							Settings
						</div>
					</div>
					{tabName == "profile" ? (
						<UserProfile user={selectedUser} />
					) : tabName == "listings" ? (
						<UserListings users={selectedUser} />
					) : null}
				</div>
			</div>
		</div>
	);
}
