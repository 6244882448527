import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import { AllListings } from "../../../Classes/AllListings";
import { Statuses } from "../../../Utilities/Enums";
import { Editor } from "@tinymce/tinymce-react";
import {
  MediaInput,
  PropertyInput,
  PropertyView,
} from "../../../Utilities/Types";
import Spinner from "../../../Utilities/Spinner";

export default function EditListing({ property }: { property?: PropertyView }) {
  console.log(property);
  const [description, setDescription] = useState(property?.description);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const initialValues: PropertyInput = {
    id: property?.id,
    name: property?.name,
    title: property?.title,
    address: property?.address,
    description: property?.description,
    sellMyself: property?.sellMyself,
    price: property?.price,
    numberOfBedrooms: property?.numberOfBedrooms,
    numberOfBathrooms: property?.numberOfBathrooms,
    isDraft: property?.isDraft,
    isActive: property?.isActive,
    isForRent: property?.isForRent,
    isForSale: property?.isForSale,
    propertyTypeId: property?.propertyTypeId,
    mediaFiles: [],
    state: property?.state,
    lga: property?.lga,
    area: property?.area,
    isRequest: false,
    comment: "",
    budget: "",
  };
  const [mediaFiles, setMediaFiles] = useState<MediaInput[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [videoPreviews, setVideoPreviews] = useState<string[]>([]);

  const grabUploadedFile = (uploadedFiles: any) => {
    console.log(uploadedFiles);
    extractPreviewFromFile(uploadedFiles);
    console.log({ previews });

    uploadedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => {
        console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
      };
      reader.onerror = () => {
        console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
      };
      reader.onload = async () => {
        // Do whatever you want with the file contents
        const binaryStr = (reader.result as string).split(",")[1];
        console.log(reader.result);
        //console.log(binaryStr);
        console.log(binaryStr);
        await composeMedia(binaryStr, file);
      };
      console.log(file);
      reader.readAsDataURL(file);
    });
  };

  const grabUploadedVideoFile = (uploadedFiles: any) => {
    console.log(uploadedFiles);
    extractPreviewFromFile(uploadedFiles, true);
    console.log({ previews });

    uploadedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => {
        console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
      };
      reader.onerror = () => {
        console.log("Errrrrrrrrrrrrrooooooooooorrrrrrr");
      };
      reader.onload = async () => {
        // Do whatever you want with the file contents
        const binaryStr = (reader.result as string).split(",")[1];
        console.log(reader.result);
        //console.log(binaryStr);
        console.log(binaryStr);
        await composeMedia(binaryStr, file);
      };
      console.log(file);
      reader.readAsDataURL(file);
    });
  };

  const extractPreviewFromFile = async (
    uploadedFiles: any[],
    isVideo = false
  ) => {
    var newState: string[] = [];
    uploadedFiles.map((element) => {
      console.log(element);
      newState.push(URL.createObjectURL(element));
    });
    if (isVideo) {
      setVideoPreviews([...videoPreviews, ...newState]);
      return;
    }
    setPreviews([...previews, ...newState]);
  };

  const composeMedia = async (bytes: any, file: any) => {
    var files = [...mediaFiles];
    console.log(files);
    var newMedia: MediaInput = {
      name: file.name,
      extention: getFileExtension(file.name),
      base64String: bytes,
      propertyId: 0,
      isImage:
        getFileExtension(file.name) == "jpeg" ||
        getFileExtension(file.name) == "jpg"
          ? true
          : false,
      isVideo: getFileExtension(file.name) == "mp4" ? true : false,
      isDocument: getFileExtension(file.name) == "pdf" ? true : false,
    };

    files.push(newMedia);
    setMediaFiles([...files]);
  };

  const getFileExtension = (fileName: any) => {
    return fileName.split(".")[1];
  };

  const update = async (details: PropertyInput) => {
    setLoading(true);
    details.mediaFiles = mediaFiles;
    if (description) {
      details.description = description;
    }
    try {
      let data = await AllListings.Update(details);
      if (data.status) {
        addToast(data.message, { appearance: "success", autoDismiss: true });
        setLoading(false);
        window.location.reload();
        return;
      }
      addToast(data.message, { appearance: "error", autoDismiss: true });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteMedia = async (id: any) => {
    try {
      const data = await AllListings.DeleteMedia(id);
      if (data.status) {
        addToast(data.message, { appearance: "success", autoDismiss: true });
        window.location.reload();
        return;
      }

      addToast(data.message, { appearance: "error", autoDismiss: true });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (
          values: PropertyInput,
          { setSubmitting }: FormikHelpers<PropertyInput>
        ) => {
          console.log(values);
          await update(values);
        }}
      >
        <Form>
          <div className="row">
            <div className="col-lg-6">
              <div className="property-info-box w-100">
                <div className="input-box">
                  <div className="input-label">Property Name</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.name}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Property Type</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.propertyType}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Legal Title</div>
                  <Field
                    id="name"
                    name="title"
                    className="formfield"
                    value={property?.title}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">No. of Bedrooms</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.numberOfBedrooms}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">No. of Toilets</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.numberOfBathrooms}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Price</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.price}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">State</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.state}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Locality</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.lga}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Area</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.area}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Seller</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.createdByUser?.fullName}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Seller's Email</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.createdByUser?.email}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Seller's Mobile Number</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.createdByUser?.phoneNumber}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="property-info-box w-100">
                <div className="input-box d-none">
                  <div className="input-label">Google Map/Streetview</div>
                  <input type="text" className="formfield" placeholder="" />
                </div>
                <div className="input-box">
                  <div className="input-label">Details</div>
                  <Field
                    as={Editor}
                    init={{
                      height: 398,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      skin: "material-classic",
                      toolbar:
                        "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link",
                    }}
                    onEditorChange={(e: any) => {
                      console.log(e);
                      setDescription(e);
                    }}
                    apiKey={"h48cw4xuitutcnrtl0o32kl2h1u1pedw4y94bnxabwnr74dg"}
                    id="description"
                    name="description"
                    className="input-txt"
                    style={{ height: "150px" }}
                  />
                </div>
                <div className="single-form">
                  <label className="admin-form">Add Images</label>
                  <div className="flex admin-media-cover">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      maxFiles={6}
                      onDrop={(acceptedFiles) =>
                        grabUploadedFile(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()} className="admin-img-field">
                            <input {...getInputProps()} />
                            {/* <i className="far fa-image" />
									Upload pictures */}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {property?.mediaFiles && property?.mediaFiles?.length > 0
                      ? property?.mediaFiles.map((file, index) => {
                          return file.isImage ? (
                            <div className="img-trash-wrapper">
                              <i
                                className="fas fa-trash img-trash"
                                onClick={async () => await deleteMedia(file.id)}
                              ></i>
                              <img
                                src={file.url}
                                alt=""
                                className="admin-img-field"
                              />
                            </div>
                          ) : null;
                        })
                      : null}
                    {previews.map((preview, index) => {
                      return (
                        <img src={preview} className="admin-img-field" alt="" />
                      );
                    })}
                  </div>
                </div>
                <div className="single-form">
                  <label className="admin-form">Add Interactive 3D tour</label>
                  <div className="flex admin-media-cover">
                    <Dropzone
                      accept="video/mp4"
                      maxFiles={6}
                      onDrop={(acceptedFiles) =>
                        grabUploadedVideoFile(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()} className="admin-img-field">
                            <input {...getInputProps()} />
                            {/* <i className="far fa-image" />
									Upload pictures */}
                          </div>
                        </section>
                      )}
                    </Dropzone>

                    {property?.mediaFiles && property?.mediaFiles?.length > 0
                      ? property?.mediaFiles.map((file, index) => {
                          return file.isVideo ? (
                            <div className="img-trash-wrapper">
                              <i
                                className="fas fa-trash img-trash"
                                onClick={async () => await deleteMedia(file.id)}
                              ></i>
                              <video
                                src={file.url}
                                className="admin-img-field"
                                controls
                              />
                            </div>
                          ) : null;
                        })
                      : null}
                    {videoPreviews.map((preview, index) => {
                      return (
                        <video src={preview} className="admin-img-field" />
                      );
                    })}
                  </div>
                </div>
                <div className="list-tip">Bank Details</div>
                <div className="input-box">
                  <div className="input-label">Bank</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={property?.createdByUser?.bank}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">Account Number</div>
                  <Field
                    id="name"
                    name="name"
                    className="formfield"
                    value={
                    	property?.createdByUser?.accountNumber
                    }
                  />
                </div>
                <button type="submit" className="primary-btn w-100">
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
