import { url } from "inspector";
import { PropertyModel } from "../Types/api";
import {
  Endpoints,
  Statuses,
  HttpMethods,
  ErrorMessages,
} from "../Utilities/Enums";
import Fetch from "../Utilities/Fetch";
import {
  ApiResponse,
  PagedApiResponse,
  PropertyInput,
  PropertyView,
  Response,
  UserView,
} from "../Utilities/Types";

export class AllListings {
  _propertyView?: PropertyView;
  _propertyInput?: PropertyInput;

  constructor(public id?: number) {}

  public static allListings = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.AllListings}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static allPendingRentListings = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.PENDING_RENT}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static allRentListings = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.RENT}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };


  public static allRequest = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.Requests}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static allEnquires = async (url?: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.Enquires}${url ? url : ""}`
      )) as unknown as PagedApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static addProperty = async (details: PropertyModel) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.Create}`,
        HttpMethods.POST,
        details
      )) as unknown as ApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static updateProperty = async (details: PropertyInput) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.Create}`,
        HttpMethods.POST,
        details
      )) as unknown as ApiResponse;
      let response: Response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static GetById = async (id: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.GET}${id}`
      )) as unknown as ApiResponse;
      response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static GetRequestById = async (id: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.GetRequest}${id}`
      )) as unknown as ApiResponse;
      response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static Update = async (property: PropertyInput) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        Endpoints.Listings.UpdateListing,
        HttpMethods.POST,
        property
      )) as unknown as ApiResponse;
      response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.error(error);
      return response;
    }
  };

  public static ListTypes = async () => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      const data = (await Fetch(
        Endpoints.Listings.ListTypes
      )) as unknown as ApiResponse;
      response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.error(error);
      return response;
    }
  };

  public static DeleteMedia = async (id: any) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.DeleteMedia}${id}`
      )) as unknown as ApiResponse;
      response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };

  public static Delete = async (id?: number) => {
    let response: Response = {
      status: Statuses.FALSE,
      message: ErrorMessages.FAILED,
      data: null,
    };
    try {
      let data = (await Fetch(
        `${Endpoints.Listings.DeleteProperty}${id}`
      )) as unknown as ApiResponse;
      response = {
        status: data.status,
        message: data.message,
        data: data.data,
      };
      return response;
    } catch (error) {
      console.log(error);
      return response;
    }
  };
  public static Approve = async (id?: number) => {
	let response: Response = {
		status: Statuses.FALSE,
		message: ErrorMessages.FAILED,
		data: null,
	  };
	  try {
		let data = (await Fetch(
		  `${Endpoints.Listings.APPROVE}${id}`
		)) as unknown as ApiResponse;
		response = {
		  status: data.status,
		  message: data.message,
		  data: data.data,
		};
		return response;
	  } catch (error) {
		console.log(error);
		return response;
	  }
  };

  public static Reject = async (id?: number) => {
	let response: Response = {
		status: Statuses.FALSE,
		message: ErrorMessages.FAILED,
		data: null,
	  };
	  try {
		let data = (await Fetch(
		  `${Endpoints.Listings.REJECT}${id}`
		)) as unknown as ApiResponse;
		response = {
		  status: data.status,
		  message: data.message,
		  data: data.data,
		};
		return response;
	  } catch (error) {
		console.log(error);
		return response;
	  }
  };
}
