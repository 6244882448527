import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { AllListings } from "../../Classes/AllListings";
import { PropertyRequestView } from "../../Types/api";
import Modal from "../../Utilities/Modal/Modal";
import { PropertyView } from "../../Utilities/Types";
import AddMatch from "./ListingModals/AddMatch";
import AddProperty from "./ListingModals/AddProperty";

type PropertyParams = {
  id: string;
};
export default function Matches() {
  const { id } = useParams<PropertyParams>();
  const [request, setRequest] = useState<PropertyRequestView>();
  const [openModal, setOpenModal] = useState(false);
  const { addToast } = useToasts();
  let history = useHistory();

  const close = async () => {
    setOpenModal(false);
    await getRequest();
  };
  const getRequest = async () => {
    let data = await AllListings.GetRequestById(parseInt(id));
    console.log(data);

    if (!data.status) {
      addToast(data.message, { appearance: "error", autoDismiss: true });
      return;
    }
    setRequest(data.data);
    return;
  };
  useEffect(() => {
    const getInitialData = async () => {
      await getRequest();
    };
    getInitialData();
  }, []);
  return (
    <>
      <Modal open={openModal} close={close}>
        <AddProperty close={close} requestId={id as unknown as number}/>
      </Modal>
      
      <div className="inner-wrapper no-bg">
        <div className="flex">
          <div className="name-side">
            <div className="flex pt-3">
              <div className="back-icon">
                <i
                  className="fas fa-chevron-left"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <div className="listing-full">{request?.propertyType?.name}</div>
            </div>
            <div className="per-details">
              <label className="tab-label">User</label>
              <span className="tab-text">{`${request?.user?.firstName} ${request?.user?.firstName}`}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Status</label>
              <span className="tab-text">Enquires</span>
            </div>
            <div className="per-details">
              <label className="tab-label">State</label>
              <span className="tab-text">{request?.state}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Locality</label>
              <span className="tab-text">{request?.lga}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Area</label>
              <span className="tab-text">{request?.lga}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Budget</label>
              <span className="tab-text">{request?.budget}</span>
            </div>
            <div className="per-details">
              <label className="tab-label">Matching Status</label>
              <span className="tab-text">{request?.status}</span>
            </div>
          </div>
          <div className="match-side">
            <div className="flex-item">
              <div className="listing-full">Matches</div>
              <button
                className="btn-outline"
                onClick={() => setOpenModal(true)}
              >
                Add Match
              </button>
            </div>
            {/* Empty Matches  */}
            <div className="matches-box d-none">
              <h4 className="listing-full dull">No matches added yet</h4>
            </div>
            {/* With Matches  */}
            <div className="table-box">
              <table className="table table-hover">
                <thead className="ag-thead">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Location</th>
                    <th scope="col" />
                    <th scope="col" />
                    <th scope="col">Price</th>
                    {/* <th scope="col">Status</th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {request?.matches?.map((match, index) => {
                    return (
                      <tr>
                        <th scope="row">{match.property?.name}</th>
                        <td className="user">{match.property?.address}</td>
                        <td>{match.property?.state}</td>
                        <td>L{match.property?.lga}</td>
                        <td>{match.property?.price}</td>
                        {/* <td>Accepted</td> */}
                        <td className="delete" />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
