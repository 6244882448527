import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { PropertyView } from "../../Utilities/Types";
//@ts-ignore
import Naira from "react-naira";
import Pagination from "../../Utilities/Pagination";

export default function RequestListings({
	listings,
	search,
	list,
}: {
	listings: any;
	search?: any;
	list: any;
}) {
	let properties = listings;
	const [miniTab, setMiniTab] = useState("pending");
	const setTab = (tabname: string) => {
		setMiniTab(tabname);
	};
	return (
		<div className="inner-wrapper">
			<div className="flex all-tabs d-none">
				<div
					className={`mini-tabs ${miniTab == "pending" ? "active" : ""}`}
					onClick={() => setTab("pending")}
				>
					Pending
				</div>
				<div
					className={`mini-tabs ${miniTab == "ongoing" ? "active" : ""}`}
					onClick={() => setTab("ongoing")}
				>
					Ongoing
				</div>
				<div
					className={`mini-tabs ${miniTab == "resolved" ? "active" : ""}`}
					onClick={() => setTab("resolved")}
				>
					Resolved
				</div>
			</div>

			<div className="flex-item mb-4">
				<div className="admin-search-bar-2">
					<i className="fas fa-search" />
					<input
						type="text"
						className="property-search"
						placeholder="Search"
						onChange={(e) => search(e.target.value)}
					/>
				</div>
			</div>
			<div className="table-box">
				<table className="table table-hover">
					<thead className="ag-thead">
						<tr>
							<th scope="col">Type</th>
							<th scope="col">User</th>
							<th scope="col">State</th>
							<th scope="col">Locality</th>
							<th scope="col">Area</th>
							<th scope="col">Budget</th>
							<th scope="col">Matching Status</th>
						</tr>
					</thead>
					<tbody>
						{properties.map((listing: any, i: any) => {
							return (
								<tr key={i}>
									<Link to={`/listings/request/${listing?.id}`}>
										<th scope="row">{listing.propertyType?.name}</th>
									</Link>
									<td className="user">{listing.user?.firstName}</td>
									<td>{listing.state}</td>
									<td>{listing.lga}</td>
									<td>{listing.area ? listing.area : "-"}</td>
									<td>
										<Naira>{listing.budget}</Naira>
									</td>

									<td
										className={`status ${
											listing.status && listing.status == "PENDING"
												? "p"
												: listing.status == "SUCCESSFUL"
												? "s"
												: "f"
										}`}
									></td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="pm-space" />
			<Pagination details={listings} list={list} />
		</div>
	);
}
